import axios from 'axios';
import axiosRetry from 'axios-retry';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import OfflineModeUtils from '@shared/utils/OfflineModeUtils';

if (process.server) {
  axiosRetry(axios, { retries: 10 });
}
import get from 'lodash/get';

import CustomErrorEnum from '@/enums/CustomErrorEnum';

import OfflineModeService from '@/services/OfflineModeService';
// import {
//   SESSION_ERROR,
//   SESSION_EXCEED_ERROR
// } from '@shared/enums/CustomErrorEnum';
// const sessionsRequestsTemplate = '/rest/session/';
// let interruptRequests = false;
// let disableInterceptorOperations = false;
// let i18n;
const MAX_TIMEOUT = AppConstantsUtil.MAX_TIMEOUT;
const context = {
  store: {}
};

async function init({ store }) {
  context.store = store;
  // ({ default: i18n } = await import('@/i18n'));
}

const CancelToken = axios.CancelToken;

const ignoreCodesMap = {
  501: CustomErrorEnum.UNSUPPORTED_REST_REQUEST,
  400: CustomErrorEnum.UNSUPPORTED_REST_REQUEST
};

axios.interceptors.response.use(
  async response => {
    OfflineModeService.setResponse(response);
    return response;
  },
  error => {
    const status = get(error, 'request.status', null);

    const cancelType = get(error, 'message.type', null);
    const isIgnoreStatusCode = ignoreCodesMap.hasOwnProperty(status);
    if (isIgnoreStatusCode) {
      error.type = CustomErrorEnum.UNSUPPORTED_REST_REQUEST;
    } else if (
      cancelType === CustomErrorEnum.CANCEL_REQUEST ||
      isBackground()
    ) {
      error.type = CustomErrorEnum.CANCEL_REQUEST;
    } else {
      OfflineModeService.getErrorResponse(error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function(request) {
    OfflineModeService.setRequest(request);
    return request;
  },
  function(error) {
    OfflineModeService.getErrorRequest(error);
    return Promise.reject(error);
  }
);

function http(config) {
  if (!OfflineModeUtils.checkIsOnline() && !process.env.IS_BLOG) {
    const err = new Error(
      'Network Error http try to send request in offline mode'
    );
    err.config = config;
    return Promise.reject(err);
  }
  if (process.client) {
    config.timeout = MAX_TIMEOUT;
  }
  let cancel;
  config.cancelToken = new CancelToken(function executor(c) {
    // An executor function receives a cancel function as a parameter
    cancel = c;
  });
  const promise = axios(config);
  promise.cancel = cancel;
  return promise;
}

function getRequest(url, options = {}) {
  if (!OfflineModeUtils.checkIsOnline() && !process.env.IS_BLOG) {
    const err = new Error(
      'Network Error getRequest try to send get request in offline mode'
    );
    err.config = { url, options };
    return Promise.reject(err);
  }
  if (process.client) {
    options.timeout = options.maxTimeout || MAX_TIMEOUT;
  }
  return axios.get(url, options);
}

function getUri(...args) {
  return axios.getUri(...args);
}

function isBackground() {
  const getters = context?.store?.getters;
  if (process.server || !getters) {
    return;
  }
  return getters['ContextStore/isAppInBackground'];
}

// function _delayServiceVariablesUpdate(value) {
//   setTimeout(() => {
//     _setServiceVariables(value);
//   }, 2000);
// }

// function _setServiceVariables(value) {
//   interruptRequests = value;
//   disableInterceptorOperations = value;
// }

export default {
  http,
  init,
  get: getRequest,
  getUri
};
