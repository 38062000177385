import RestService from '@/services/RestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
import PublicationsLoaderFSService from '@/services/PublicationsLoader/PublicationsLoaderFSService';
import PublicationsLoaderRemoteService from '@/services/PublicationsLoader/PublicationsLoaderRemoteService';
import PublicationsLoaderSDCardService from '@/services/PublicationsLoader/PublicationsLoaderSDCardService';
import MaterialReqParamsFactory from '@/classes/factories/Materials/MaterialReqParamsFactory';
import isEmpty from 'lodash/isEmpty';

const logger = LoggerFactory.getLogger('LibraryStoreService.js');

import AppModeEnum from '@/enums/AppModeEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';

const LIBRARY_JSON = 'library.json';

const libraryStoreServiceContext = {
  isEditor: process.env.IS_EDITOR,
  serverUrl: ''
};

class LibraryData {
  constructor(data) {
    this.libraryData = data;
  }

  extractPublicationsData() {
    return this.libraryData.map(data => ({
      publicationsData: data.rawData.publicationsData,
      source: data.source
    }));
  }

  extractRelatedStudyGuides() {
    const dataLength = this.libraryData.length;
    for (let i = dataLength - 1; i >= 0; i--) {
      const relatedStudyGuides = this.libraryData[i].rawData.relatedStudyGuides;
      if (!isEmpty(relatedStudyGuides)) {
        return relatedStudyGuides;
      }
    }
    return {};
  }

  extractSuggestedBooks() {
    const dataLength = this.libraryData.length;
    for (let i = dataLength - 1; i >= 0; i--) {
      const suggestedBooks = this.libraryData[i].rawData.suggestedBooks;
      if (suggestedBooks) {
        return suggestedBooks;
      }
    }
    return [];
  }
}

class LibraryServiceClient {
  constructor() {
    this.sourcesMap = libraryStoreServiceContext.isEditor
      ? {
          [AssetResourcesEnum.REMOTE]: PublicationsLoaderRemoteService
        }
      : {
          [AssetResourcesEnum.FS]: PublicationsLoaderFSService,
          [AssetResourcesEnum.SD_CARD]: PublicationsLoaderSDCardService,
          [AssetResourcesEnum.REMOTE]: PublicationsLoaderRemoteService
        };
  }

  async init(Context) {
    try {
      libraryStoreServiceContext.serverUrl = Context.serverUrl;

      await Promise.all([
        PublicationsLoaderFSService.init(Context),
        PublicationsLoaderRemoteService.init(Context),
        PublicationsLoaderSDCardService.init(Context)
      ]);
    } catch (err) {
      logger.fatal(
        `Error while initializing Library store service. Error: ${err}`
      );
    }
  }

  setUserId(userId) {
    PublicationsLoaderFSService.setUserId(userId);
  }

  loadStoreData({
    publicationsTypes,
    errorHandler,
    forceRemote,
    isSuggestionsEnabled,
    isGuestUser,
    isLoggingOut
  }) {
    const sourceList = Object.keys(this.sourcesMap);
    const promisesList = sourceList
      .map(source => ({ source, service: this.sourcesMap[source] }))
      .map(({ source, service }) =>
        service
          .getPublicationsData({
            publicationsTypes,
            errorHandler: errorHandler(source),
            forceRemote,
            isSuggestionsEnabled,
            isGuestUser,
            isLoggingOut
          })
          .then(rawData => ({ source, rawData }))
      );

    return Promise.all(promisesList)
      .then(rawData => new LibraryData(rawData))
      .catch(err => {
        logger.error(`Error getting store data: ${err}`);
      });
  }

  _setDefaultAssetsSource(
    publication,
    defaultSource = AssetResourcesEnum.REMOTE
  ) {
    if (publication.type === PublicationsTypes.COLLECTION) {
      publication.items.forEach(item => (item.assetsSource = defaultSource));
    }
    publication.assetsSource = defaultSource;

    return publication;
  }

  async searchBooks(params) {
    params.fileName = LIBRARY_JSON;
    const response = await RestService.restRequest(
      'get',
      'Publications',
      'searchFilteredBooks',
      params
    );
    const data = response.data || {};
    for (const lang in data?.libraryData) {
      data?.libraryData?.[lang]?.forEach(pub =>
        this._setDefaultAssetsSource(pub)
      );
    }
    return data;
  }

  _getPublicationsSourcesList() {
    return libraryStoreServiceContext.isEditor
      ? [AssetResourcesEnum.REMOTE]
      : [
          AssetResourcesEnum.FS,
          AssetResourcesEnum.SD_CARD,
          AssetResourcesEnum.REMOTE
        ];
  }

  savePublicationData(tableName, publication) {
    return PublicationsLoaderFSService.savePublicationData(
      tableName,
      publication
    );
  }
  removePublicationById(tableName, publicationId) {
    return PublicationsLoaderFSService.removePublicationById(
      tableName,
      publicationId
    );
  }

  removeTable(tableName) {
    return PublicationsLoaderFSService.removeTable(tableName);
  }

  async clearTable(tableName, source, exclude) {
    const backup = await PublicationsLoaderFSService.getPublicationsByIds(
      tableName,
      exclude
    );
    await PublicationsLoaderFSService.removeTable(tableName);
    await PublicationsLoaderFSService.savePublications(tableName, backup);
  }

  async createReport(bookIds) {
    const res = await RestService.restRequest(
      'post',
      'Publications',
      'createReport',
      {
        bookIds
      }
    );
    return res.data;
  }

  async createStudyGuide(publicationId) {
    const paramsBuilder = MaterialReqParamsFactory.createBuilder();
    const params = paramsBuilder
      .setAppMode(AppModeEnum.EDITOR)
      .setBookId(publicationId)
      .build();
    const res = await RestService.restRequest(
      'post',
      'Materials',
      'update',
      params
    );
    return res.data;
  }

  getRemoteBookInfo(publicationId) {
    const reqData = {
      id: publicationId
    };

    return RestService.restRequest(
      'get',
      'Publications',
      'getBookInfo',
      reqData
    ).then(resp => {
      return resp.data;
    });
  }

  async getNewBookIds() {
    try {
      const newBooksIdsData = await RestService.restRequest(
        'get',
        'Publications',
        'getNewBookIds'
      );
      return newBooksIdsData.data;
    } catch (error) {
      logger.log(error);
    }
  }

  getRemoteStudyGuideInfo(publicationId) {
    const reqData = {
      id: publicationId
    };

    return RestService.restRequest(
      'get',
      'ContentProvider',
      'getPublicationDetails',
      reqData
    ).then(resp => {
      return resp.data;
    });
  }

  persistDefaultStudyGuide(bookId, defaultStudyGuideId) {
    var reqData = {
      bookId,
      defaultStudyGuideId
    };
    return RestService.restRequest(
      'post',
      'Publications',
      'persistDefaultStudyGuide',
      reqData
    );
  }

  persistPublicationAccessStatus(statuses) {
    return RestService.restRequest(
      'post',
      'Publications',
      'persistAccessStatus',
      statuses
    );
  }

  erasePublication(publicationId, type) {
    const reqData = {
      publicationId,
      type
    };
    return RestService.restRequest(
      'delete',
      'Publications',
      'erasePublication',
      reqData
    );
  }

  persistMaterialStatus(id, status, type) {
    const reqData = {
      id,
      status,
      type
    };
    return RestService.restRequest(
      'post',
      'Publications',
      'persistStatus',
      reqData
    );
  }

  createStudyGuideCopy(studyGuideId) {
    const reqData = {
      studyGuideId
    };
    return RestService.restRequest('post', 'StudyGuide', 'createCopy', reqData);
  }

  createLibrarySet() {
    return RestService.restRequest('post', 'LibrarySet', 'librarySet', {});
  }

  createCopyLibrarySet(originalId) {
    return RestService.restRequest('post', 'LibrarySet', 'copyLibrarySet', {
      originalId
    });
  }

  persistLibrarySet(query) {
    return RestService.restRequest('put', 'LibrarySet', 'librarySet', {
      query
    });
  }

  pushChangesToOriginalLibrarySet(originalId, copyId) {
    const reqData = {
      originalId,
      copyId
    };
    return RestService.restRequest(
      'put',
      'LibrarySet',
      'copyLibrarySet',
      reqData
    );
  }

  pushChangesToOriginalStudyGuide(originalStudyGuideId, copyStudyGuideId) {
    const reqData = {
      originalStudyGuideId,
      copyStudyGuideId
    };
    return RestService.restRequest(
      'post',
      'StudyGuide',
      'pushChanges',
      reqData
    );
  }
}

class LibraryServiceServer extends LibraryServiceClient {
  constructor() {
    super();
    this.sourcesMap = {
      [AssetResourcesEnum.REMOTE]: PublicationsLoaderRemoteService
    };
  }
}

const libraryService = process.server
  ? new LibraryServiceServer()
  : new LibraryServiceClient();

export default libraryService;
