import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('toolbarMixin.vue');

import AppToolbarFactory from '@/classes/factories/AppToolbarFactory';
import Utils from '@/services/utils/Utils';

import ApplicationToolbarItemsEnum from '@/enums/ApplicationToolbarItemsEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';

import LogoControl from '@/components/controls/LogoControl/LogoControl';
import AppNameControl from '@/components/controls/AppNameControl/AppNameControl';
import BurgerMenuControl from '@/components/controls/BurgerMenuControl/BurgerMenuControl';
import BackToLibraryControl from '@/components/controls/BackToLibraryControl/BackToLibraryControl';
import BackToBookControl from '@/components/controls/BackToBookControl/BackToBookControl';
import ToLibraryIconControl from '@/components/controls/ToLibraryIconControl/ToLibraryIconControl';
import ToCompilationControl from '@/components/controls/ToCompilationControl/ToCompilationControl';
import ToCompilationIconControl from '@/components/controls/ToCompilationIconControl/ToCompilationIconControl';
import ToAboutIconControl from '@/components/controls/ToAboutIconControl/ToAboutIconControl';
import ToPricingIconControl from '@/components/controls/ToPricingIconControl/ToPricingIconControl';
import ToPricingControl from '@/components/controls/ToPricingControl/ToPricingControl';
import BackToAboutControl from '@/components/controls/BackToAboutControl/BackToAboutControl';
import SingInControl from '@/components/controls/SingInControl/SingInControl';
import SingUpControl from '@/components/controls/SingUpControl/SingUpControl';
import RecentBookIconControl from '@/components/controls/RecentBookIconControl/RecentBookIconControl';
import RecentBookLabelControl from '@/components/controls/RecentBookLabelControl/RecentBookLabelControl';
import ToolbarActivityControl from '@/components/controls/ToolbarActivityControl/ToolbarActivityControl.vue';
import VocabularyAssessmentPreviousButtonControl from '@/components/controls/VocabularyAssessmentPreviousButtonControl/VocabularyAssessmentPreviousButtonControl';
import VocabularyAssessmentBackButtonControl from '@/components/controls/VocabularyAssessmentBackButtonControl/VocabularyAssessmentBackButtonControl';
import VocabularyAssessmentPageTitleControl from '@/components/controls/VocabularyAssessmentPageTitleControl/VocabularyAssessmentPageTitleControl';
import OnboardingBackButtonControl from '@/components/controls/OnboardingBackButtonControl/OnboardingBackButtonControl';
import OnboardingPageTitleControl from '@/components/controls/OnboardingPageTitleControl/OnboardingPageTitleControl';
import AvatarControl from '@/components/controls/AvatarControl/AvatarControl';
import BurgerControl from '@/components/controls/BurgerControl/BurgerControl';
import OpenSearchControl from '@/components/controls/OpenSearchControl/OpenSearchControl';
import OpenSearchControlMid from '@/components/controls/OpenSearchControlMidBlock/OpenSearchControlMidBlock';
import SearchInsideBookControl from '@/components/controls/SearchInsideBookControl/SearchInsideBookControl';
import SearchHeaderControl from '@/components/controls/SearchHeaderControl/SearchHeaderControl';
import FakeSearchControl from '@/components/controls/FakeSearchControl/FakeSearchControl';
import BackFromPublicationControl from '@/components/controls/BackFromPublicationControl/BackFromPublicationControl';
import ShareControl from '@/components/controls/ShareControl/ShareControl';

export default {
  components: {
    LogoControl,
    AppNameControl,
    BackToLibraryControl,
    BackToBookControl,
    ToLibraryIconControl,
    ToPricingIconControl,
    ToAboutIconControl,
    ToCompilationIconControl,
    BurgerMenuControl,
    ToCompilationControl,
    ToPricingControl,
    BackToAboutControl,
    SingInControl,
    SingUpControl,
    RecentBookIconControl,
    RecentBookLabelControl,
    ToolbarActivityControl,
    VocabularyAssessmentPreviousButtonControl,
    VocabularyAssessmentBackButtonControl,
    VocabularyAssessmentPageTitleControl,
    OnboardingBackButtonControl,
    OnboardingPageTitleControl,
    AvatarControl,
    BurgerControl,
    OpenSearchControl,
    OpenSearchControlMid,
    SearchInsideBookControl,
    FakeSearchControl,
    SearchHeaderControl,
    BackFromPublicationControl,
    ShareControl
  },
  data() {
    return {
      hydrationFinished: false
    };
  },
  computed: {
    displayContext() {
      const appState = this.$store.getters['ContextStore/appState'];
      const isGuestUser = this.$store.getters['UserStore/isGuestUser'];
      const isUserEmpty = this.$store.getters['UserStore/isUserEmpty'];
      const isLoggedIn = this.$store.getters['UserStore/isLoggedIn'];
      const brand = this.$store.getters['ContextStore/brand'];
      const isOcean = brand === BrandsEnum.OCEAN;
      const isDevice = this.$store.getters['ContextStore/isDevice'];
      const isEditor = this.$store.getters['ContextStore/isEditorApp'];
      const isBlog = this.$store.getters['ContextStore/isBlogApp'];
      const mediaSize = this.$store.getters['MediaDetectorStore/mediaSize'];
      const isPresentPublicationMounted = this.$store.getters[
        'ContextStore/getIsPresentPublicationMounted'
      ];
      const isActivityGoalEnabled = this.$store.getters[
        'ContextStore/isActivityGoalEnabled'
      ];
      const isPurchaseEnabled = this.$store.getters[
        'ContextStore/isPurchaseEnabled'
      ];
      const isCompilationOpen = this.$store.getters[
        'PublicationStore/isCompilationOpen'
      ];
      const isOnline = this.$store.getters['ContextStore/isOnline'];

      return {
        appState,
        mediaSize: this.hydrationFinished ? mediaSize : {},
        isGuestUser: this.hydrationFinished ? isGuestUser : true,
        isUserEmpty,
        isLoggedIn: this.hydrationFinished ? isLoggedIn : false,
        isDevice,
        isEditor,
        isBlog,
        isOcean,
        isActivityGoalEnabled,
        isPurchaseEnabled,
        isCompilationOpen,
        isOnline,
        isPresentPublicationMounted,
        toolbarVisible: this.toolbarVisible
      };
    },
    isBookOpened() {
      const appState = this.$store.getters['ContextStore/appState'];
      return (
        appState === AppStateEnum.PRESENT_PUBLICATION ||
        appState === AppStateEnum.NOT_RENDERED_BOOK
      );
    }
  },
  mounted() {
    this.hydrationFinished = true;
  },
  methods: {
    mixinFilterByContext(allItems, displayContext, customAttrs) {
      // make structure of toolbar items

      const filteredItems = toolbarItems => {
        const preparedToolbarItems = [];
        for (let i = 0; i < toolbarItems.length; i++) {
          if (Array.isArray(toolbarItems[i])) {
            preparedToolbarItems.push(filteredItems(toolbarItems[i]));
          } else {
            if (toolbarItems[i].isSerialized) {
              toolbarItems[i] = this.$_createToolbarItem(
                toolbarItems[i].name,
                customAttrs
              );
            }

            const isDestroyed = toolbarItems[i].isDestroyedFn(displayContext);
            if (!isDestroyed) {
              preparedToolbarItems.push(toolbarItems[i]);
            }
          }
        }
        return preparedToolbarItems;
      };

      return filteredItems(allItems);
    },
    mixinBuildAllItems(toolbarItemsOrder, customAttrs = {}) {
      return toolbarItemsOrder.reduce((toolbarItems, element) => {
        if (Array.isArray(element)) {
          toolbarItems.push(this.mixinBuildAllItems(element, customAttrs));
        } else {
          toolbarItems.push(this.$_createToolbarItem(element, customAttrs));
        }
        return toolbarItems;
      }, []);
    },
    $_createToolbarItem(toolbarItemType, customAttrs) {
      const id = `toolbar-item-id-${toolbarItemType}-${Utils.shortUuid()}`;
      const toolbarItemBuilder = AppToolbarFactory.getToolbarItemBuilder();
      const attrs = customAttrs[toolbarItemType];

      toolbarItemBuilder.setName(toolbarItemType).setAttributes(attrs);

      switch (toolbarItemType) {
        case ApplicationToolbarItemsEnum.LOGO: {
          const isDestroyedFn = displayContext => {
            return displayContext.isEditor
              ? displayContext.appState === AppStateEnum.DEVELOP_LIBRARY_SET
              : !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(LogoControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.APP_NAME: {
          const isDestroyedFn = displayContext => {
            return displayContext.isEditor
              ? displayContext.appState === AppStateEnum.DEVELOP_LIBRARY_SET
              : !displayContext.toolbarVisible ||
                  displayContext.mediaSize.narrow;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(AppNameControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.TO_LIBRARY: {
          const isDestroyedFn = displayContext => {
            return displayContext.isEditor
              ? displayContext.appState !== AppStateEnum.DEVELOP_LIBRARY_SET
              : displayContext.toolbarVisible ||
                  displayContext.appState === AppStateEnum.BLOG_ARTICLE;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(BackFromPublicationControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.SPACER: {
          toolbarItemBuilder.setId(id).setCustomClass('toolbar-spacer');
          break;
        }
        case ApplicationToolbarItemsEnum.LIBRARY: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              displayContext.mediaSize.narrow ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return (
              displayContext.appState === AppStateEnum.MANAGE_PUBLICATION ||
              displayContext.appState ===
                AppStateEnum.MANAGE_PUBLICATION_LANGUAGE
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(BackToLibraryControl.name)
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.BOOK_ICON: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.appState === AppStateEnum.PRESENT_PUBLICATION ||
              displayContext.isBlog
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(BackToBookControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.COMPILATIONS: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              !displayContext.isOcean ||
              displayContext.mediaSize.narrow ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.MANAGE_COMPILATION;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToCompilationControl.name)
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.PRICING: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              displayContext.isOcean ||
              !displayContext.isPurchaseEnabled ||
              displayContext.mediaSize.narrow ||
              displayContext.isDevice ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.PRICING;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToPricingControl.name)
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.ABOUT: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              displayContext.mediaSize.narrow ||
              !displayContext.toolbarVisible ||
              displayContext.isOcean
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.MANAGE_ABOUT;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(BackToAboutControl.name)
            .setAttributes({ link: false })
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.ACTIVITY_PROGRESS: {
          const isDestroyedFn = displayContext => {
            return (
              !displayContext.isActivityGoalEnabled ||
              displayContext.isEditor ||
              displayContext.isBlog ||
              displayContext.isGuestUser
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToolbarActivityControl.name)
            .setCustomClass('activity-progress')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.RECENTLY_OPENED_LABEL: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.toolbarVisible ||
              displayContext.appState === AppStateEnum.BLOG_ARTICLE
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(RecentBookLabelControl.name)
            .setCustomClass('recent-book-label')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_PREVIOUS_BUTTON: {
          const isDestroyedFn = displayContext => {
            return !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(VocabularyAssessmentPreviousButtonControl.name)
            .setCustomClass('vocabulary-assessment-previous-button')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_BACK_BUTTON: {
          const isDestroyedFn = displayContext => {
            return !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(VocabularyAssessmentBackButtonControl.name)
            .setCustomClass('vocabulary-assessment-back-button')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_PAGE_TITLE: {
          const isDestroyedFn = displayContext => {
            return !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(VocabularyAssessmentPageTitleControl.name)
            .setCustomClass('vocabulary-assessment-label')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.ONBOARDING_BACK_BUTTON: {
          const isDestroyedFn = displayContext => {
            return !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(OnboardingBackButtonControl.name)
            .setCustomClass('onboarding-back-button')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.ONBOARDING_PAGE_TITLE: {
          const isDestroyedFn = displayContext => {
            return !displayContext.toolbarVisible;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(OnboardingPageTitleControl.name)
            .setCustomClass('onboarding-label')
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.SIGN_IN: {
          const isDestroyedFn = displayContext => {
            const isAllowView = displayContext.mediaSize.wide;
            return (
              !isAllowView ||
              displayContext.isBlog ||
              displayContext.isLoggedIn ||
              displayContext.isDevice ||
              !displayContext.toolbarVisible ||
              displayContext.appState === AppStateEnum.MANAGE_ABOUT ||
              displayContext.appState ===
                AppStateEnum.THANK_YOU_FOR_REGISTRATION ||
              displayContext.appState === AppStateEnum.VERIFY_EMAIL
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(SingInControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.SIGN_UP: {
          const isDestroyedFn = displayContext => {
            const isAllowView = displayContext.mediaSize.wide;
            return (
              !isAllowView ||
              displayContext.isBlog ||
              displayContext.isLoggedIn ||
              displayContext.isDevice ||
              !displayContext.toolbarVisible ||
              displayContext.appState === AppStateEnum.MANAGE_ABOUT ||
              displayContext.appState ===
                AppStateEnum.THANK_YOU_FOR_REGISTRATION ||
              displayContext.appState === AppStateEnum.VERIFY_EMAIL
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(SingUpControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.RECENTLY_OPENED_ICON: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              displayContext.isBlog ||
              !displayContext.toolbarVisible
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(RecentBookIconControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.LIBRARY_ICON: {
          const isDestroyedFn = displayContext => {
            const isSearchPage =
              displayContext.appState === AppStateEnum.SEARCH;
            return (
              (isSearchPage || displayContext.isOcean
                ? false
                : !displayContext.mediaSize.narrow) ||
              displayContext.isEditor ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.MANAGE_PUBLICATION;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToLibraryIconControl.name)
            .setIsActiveFn(isActive)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.PRICING_ICON: {
          const isDestroyedFn = displayContext => {
            return (
              !displayContext.mediaSize.narrow ||
              displayContext.isOcean ||
              !displayContext.isPurchaseEnabled ||
              displayContext.isEditor ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.PRICING;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToPricingIconControl.name)
            .setIsActiveFn(isActive)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.COMPILATIONS_ICON: {
          const isDestroyedFn = displayContext => {
            return (
              !displayContext.mediaSize.narrow ||
              !displayContext.isOcean ||
              displayContext.isEditor ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.MANAGE_COMPILATION;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToCompilationIconControl.name)
            .setIsActiveFn(isActive)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.ABOUT_ICON: {
          const isDestroyedFn = displayContext => {
            return (
              !displayContext.mediaSize.narrow ||
              displayContext.isOcean ||
              displayContext.isEditor ||
              !displayContext.toolbarVisible
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.MANAGE_ABOUT;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(ToAboutIconControl.name)
            .setIsActiveFn(isActive)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.ADVANCED_SEARCH_MID: {
          const isDestroyedFn = displayContext => {
            return (
              displayContext.isEditor ||
              !(
                displayContext.isOcean &&
                displayContext.appState !== AppStateEnum.BOOKS_LIST &&
                displayContext.appState !== AppStateEnum.MANAGE_USERS
              ) ||
              !displayContext.toolbarVisible ||
              displayContext.mediaSize.narrow
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.SEARCH;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(OpenSearchControlMid.name)
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.ADVANCED_SEARCH: {
          const isDestroyedFn = displayContext => {
            return !(
              (displayContext.isOcean &&
                displayContext.appState !== AppStateEnum.BOOKS_LIST &&
                displayContext.appState !== AppStateEnum.MANAGE_USERS &&
                displayContext.toolbarVisible &&
                !displayContext.isBlog &&
                displayContext.mediaSize.narrow) ||
              (displayContext.isOcean &&
                (displayContext.appState === AppStateEnum.PRESENT_PUBLICATION ||
                  displayContext.appState === AppStateEnum.MANAGE_PUBLICATION))
            );
          };
          const isActive = displayContext => {
            return displayContext.appState === AppStateEnum.SEARCH;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(OpenSearchControl.name)
            .setIsDestroyedFn(isDestroyedFn)
            .setIsActiveFn(isActive);
          break;
        }
        case ApplicationToolbarItemsEnum.SEARCH_INSIDE_BOOK: {
          const isDestroyedFn = displayContext => {
            return !(
              displayContext.appState === AppStateEnum.PRESENT_PUBLICATION &&
              displayContext.isPresentPublicationMounted &&
              !displayContext.isCompilationOpen
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(SearchInsideBookControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        case ApplicationToolbarItemsEnum.FAKE_SEARCH: {
          const isActiveFn = displayContext => {
            return (
              displayContext.appState === AppStateEnum.MANAGE_PUBLICATION ||
              displayContext.appState ===
                AppStateEnum.MANAGE_PUBLICATION_LANGUAGE
            );
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(FakeSearchControl.name)
            .setIsActiveFn(isActiveFn)
            .setCustomClass('-fake-search');
          break;
        }
        case ApplicationToolbarItemsEnum.SEARCH_HEADER: {
          const isActiveFn = displayContext => {
            return displayContext.appState === AppStateEnum.SEARCH;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(SearchHeaderControl.name)
            .setIsActiveFn(isActiveFn)
            .setCustomClass('-page-header');
          break;
        }
        case ApplicationToolbarItemsEnum.AVATAR: {
          const isDestroyedFn = displayContext => {
            return displayContext.isBlog;
          };
          toolbarItemBuilder
            .setId(id)
            .setControlName(AvatarControl.name)
            .setIsDestroyedFn(isDestroyedFn);
          break;
        }
        // case ApplicationToolbarItemsEnum.BURGER_MENU: {
        //   const isDestroyedFn = displayContext => {
        //     return displayContext.isBlog || displayContext.toolbarVisible;
        //   };
        //   toolbarItemBuilder
        //     .setId(id)
        //     .setControlName(BurgerMenuControl.name)
        //     .setIsDestroyedFn(isDestroyedFn);
        //   break;
        // }
        default:
          logger.error(
            `Get unsupported ApplicationToolbarItems ${toolbarItemType}`
          );
          toolbarItemBuilder.setIsVisible(() => false);
          break;
      }
      return toolbarItemBuilder.build();
    }
  }
};
