import AppMenuItemsEnum from '@/enums/AppMenuItemsEnum';
import LoggerFactory from '@/services/utils/LoggerFactory';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import AppModeEnum from '@/enums/AppModeEnum';
import Brands from '@shared/enums/BrandsEnum.mjs';
import MenuFactory from '@/classes/factories/MenuFactory';
import OpenPublicationService from '@/services/OpenPublicationService';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import i18n, { localize as $t } from '@/i18n';
const logger = LoggerFactory.getLogger('ExtractControl.vue');

const context = {};

function init(_context) {
  context.router = context.router || _context.router;
  context.store = context.store || _context.store;
}

function getStore() {
  const dummyStore = {
    dispatch(store, x) {
      logger.log(`You are trying to call a dummy store: ${store}, ${x}`);
    },
    getters(value) {
      return `dummy getter ${value}`;
    }
  };
  return context.store &&
    'dispatch' in context.store &&
    'getters' in context.store
    ? context.store
    : dummyStore;
}

function getRouter() {
  const dummyRouter = {
    push(router) {
      logger.log(`You are trying to call a dummy router: ${router}`);
    }
  };
  return context.router && 'push' in context.router
    ? context.router
    : dummyRouter;
}

function createMenuItem(itemType, displayContext) {
  const store = getStore();
  const builder = MenuFactory.getMenuItemBuilder();
  const label = $t(`ApplicationMenuItem.${itemType}.label`);
  let isVisible;
  const narrow = displayContext.mediaSize.narrow;
  const isPresentPublication =
    displayContext.currentRouteName === AppStateEnum.PRESENT_PUBLICATION;

  builder.setId(itemType).setLabel(label);
  switch (itemType) {
    case AppMenuItemsEnum.PROFILE: {
      isVisible = !displayContext.isGuestUser;

      builder
        .setAutoTestId('menu-section account')
        .setLabel(displayContext.userName || label)
        .setHandler(this.applicationSettingsHandler)
        .setImgSrc(displayContext.userPicture)
        .setIcon('ico-profile')
        .setCustomClass('dummy-avatar')
        .setIsVisible(isVisible)
        .setDisabled(displayContext.isEditorApp)
        .setDescription(displayContext.userDescription);
      break;
    }
    case AppMenuItemsEnum.HOME:
      isVisible = narrow || isPresentPublication;
      builder
        .setAutoTestId('menu-section homeage')
        .setHandler(this.homeHandler)
        .setIcon(`ico-logo-${displayContext.brand}`)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.LIBRARY_READER:
      isVisible = narrow || isPresentPublication;
      builder
        .setAutoTestId('menu-section library')
        .setHandler(this.libraryHandler)
        .setIcon('ico-library-outline')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.COMPILATIONS:
      isVisible =
        displayContext.brand === Brands.FFA ||
        displayContext.brand === Brands.OCEAN ||
        isPresentPublication;
      builder
        .setAutoTestId('menu-section compilations')
        .setHandler(this.compilationHandler)
        .setIcon('ico-chat-quote-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.PRICING:
      isVisible =
        !displayContext.isDevice &&
        (narrow || isPresentPublication) &&
        displayContext.isPurchaseEnabled;
      builder
        .setAutoTestId('menu-section pricing')
        .setHandler(this.pricingHandler)
        .setIcon('ico-money-dollar-circle-line')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.ACTIVITY_DASHBOARD:
      isVisible = true;
      builder
        .setAutoTestId('menu-section activity-dashboard')
        .setHandler(this.activityDashboardHandler)
        .setIcon('ico-pie-chart-line')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.OVERVIEW:
      isVisible = false;
      builder
        .setHandler(this.overviewHandler)
        .setIcon('ico-toolbar-studyproject')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.ASSESSMENTS:
      isVisible =
        displayContext.isOnline && displayContext.brand === Brands.FFA;
      builder
        .setAutoTestId('menu-section vocabularyAssessment')
        .setHandler(this.assessmentHandler)
        .setIcon('ico-task-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.LIBRARY:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        displayContext.isAdmin &&
        !(displayContext.appState === AppStateEnum.MANAGE_PUBLICATION);
      builder
        .setHandler(this.libraryHandler)
        .setIcon('ico-toolbar-library')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.RESUME_READING:
      isVisible =
        store.getters['RecentBookStore/getLastRecentBookItem'] &&
        narrow &&
        displayContext.appState !== AppStateEnum.PRESENT_PUBLICATION;
      builder
        .setHandler(this.resumeReadingHandler)
        .setIcon('ico-toolbar-resume-reading')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.EXPLORE:
      builder
        .setAutoTestId('menu-section advancedSearch')
        .setHandler(this.exploreHandler)
        .setIcon('ico-search')
        .setIsVisible(
          displayContext.brand === Brands.FFA ||
            (displayContext.brand === Brands.OCEAN &&
              (narrow || isPresentPublication))
        );
      break;
    case AppMenuItemsEnum.DICTIONARY:
      builder
        .setHandler(this.dictionaryHandler)
        .setIcon('a-z')
        .setIsVisible(false);
      break;
    case AppMenuItemsEnum.FLASHCARDS:
      builder
        .setHandler(this.flashcardsHandler)
        .setIcon('ico-flashcards')
        .setIsVisible(false);
      break;
    case AppMenuItemsEnum.NEW_STUDY_PROJECT:
      // isVisible =
      //   displayContext.appMode === AppModeEnum.READER && displayContext.isAdmin;
      isVisible = false;
      builder
        .setHandler(this.newStudyProjectHandler)
        .setIcon('ico-toolbar-studyproject')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.CREATE_NEW_COURSE_SYLLABUS:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        displayContext.isEditor;
      builder
        .setHandler(this.createNewCourseSyllabusHandler.bind(this))
        .setIcon('ico-new-course')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.LIBRARY_SET:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        displayContext.isAdmin &&
        displayContext.isLibrarySetSupported;
      builder
        .setAutoTestId('menu-section bookSets')
        .setHandler(this.librarySetHandler)
        .setIcon('ico-archive-drawer-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.CREATE_NEW_LIBRARY_SET:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        displayContext.isAdmin &&
        displayContext.isLibrarySetSupported &&
        store.getters['LibraryStore/getAllLibrarySet'] &&
        !store.getters['LibraryStore/getLibrarySet'];

      builder
        .setHandler(this.createNewLibrarySetHandler.bind(this))
        .setIcon('ico-new-course')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.LIBRARY_CONTENT_EDITOR:
      isVisible =
        !displayContext.isGuestUser &&
        !displayContext.isDevice &&
        (displayContext.isEditor || displayContext.isAdmin) &&
        displayContext.appMode !== AppModeEnum.EDITOR &&
        !displayContext.isNative;

      builder
        .setHandler(this.libraryContentEditorHandler)
        .setIcon('ico-database-2-line')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.SHORTENER_EDITOR:
      isVisible =
        !displayContext.isGuestUser &&
        (displayContext.isEditor || displayContext.isAdmin) &&
        displayContext.appMode !== AppModeEnum.EDITOR;

      builder
        .setHandler(this.openShortenerPopup)
        .setIcon('ico-short-link')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.BLOG:
      isVisible = displayContext.brand === Brands.FFA;

      builder
        .setHandler(this.blogHandler)
        .setIcon('ico-file-document')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.MANAGE_CONTENT:
      builder
        .setHandler(this.manageContentHandler)
        .setIcon('ico-download')
        .setIsVisible(false);
      break;
    case AppMenuItemsEnum.LOGOUT:
      builder
        .setAutoTestId('menu-section signOut')
        .setHandler(this.logoutHandler)
        .setIcon('ico-logout')
        .setIsVisible(!displayContext.isGuestUser)
        .setDisabled(!displayContext.isOnline)
        .setColor('red');

      break;
    case AppMenuItemsEnum.LOGIN: {
      const allowWeb = displayContext.isGuestUser;
      const allowDevice =
        allowWeb &&
        displayContext.isDevice &&
        !displayContext.isPurchaseEnabled;

      isVisible = displayContext.isDevice ? allowDevice : allowWeb;
      builder
        .setHandler(this.loginHandler)
        .setIcon('ico-app-exit')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    }
    case AppMenuItemsEnum.ABOUT:
      {
        const brand = store.getters['ContextStore/brand'];
        isVisible =
          displayContext.appMode === AppModeEnum.EDITOR
            ? displayContext.isAdmin
            : true;
        builder
          .setHandler(this.aboutHandler)
          .setIcon(`ico-logo-${brand}`)
          .setIsVisible(isVisible);
      }
      break;
    case AppMenuItemsEnum.CONTACT_US:
      builder
        .setAutoTestId('menu-section contactUs')
        .setHandler(this.contactUsHandler)
        .setIcon('ico-mail-open-line')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(true);
      break;
    case AppMenuItemsEnum.APPLICATION_SETTINGS:
      builder
        .setAutoTestId('menu-section accountSettings')
        .setHandler(this.applicationSettingsHandler)
        .setIcon('ico-settings-5-line')
        .setIsVisible(!displayContext.isGuestUser);
      break;
    case AppMenuItemsEnum.FAVORITES:
      builder
        .setAutoTestId('menu-section favorites')
        .setHandler(this.openFavorites)
        .setIcon('ico-fav')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(!displayContext.isGuestUser);
      break;
    case AppMenuItemsEnum.DASHBOARD:
      //(Future feature)
      isVisible = false && displayContext.brand === Brands.FFA;
      builder
        .setHandler(this.openDashboard)
        .setIcon('ico-bar-chart-box-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.ACTIVITY_REPORT:
      //(Future feature)
      isVisible = false && displayContext.brand === Brands.FFA;
      builder
        .setHandler(this.openActivityReport)
        .setIcon('ico-funds-box-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.SHARE:
      builder
        .setAutoTestId('menu-section share')
        .setHandler(this.shareAppLinkHandler)
        .setIcon('ico-share-icon')
        .setIsVisible(displayContext.isOnline);
      break;
    case AppMenuItemsEnum.LIBRARY_VIEW:
      isVisible =
        displayContext.appState !== AppStateEnum.MANAGE_PUBLICATION && narrow;
      builder
        .setHandler(this.libraryViewHandler)
        .setIcon('ico-toolbar-library')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.ADMIN_PANEL:
      isVisible = displayContext.appMode !== AppModeEnum.ADMIN;
      builder
        .setAutoTestId('menu-section adminPanel')
        .setHandler(this.adminPanelHandler)
        .setIcon('ico-database-2-line')
        .setDisabled(!displayContext.isOnline)
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.REPORTS:
      isVisible = displayContext.appMode !== AppModeEnum.ADMIN;
      builder
        .setHandler(this.reportsHandler)
        .setIcon('ico-reports')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.MANAGE_USERS:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR && displayContext.isAdmin;
      builder
        .setAutoTestId('menu-section users')
        .setHandler(this.manageUsersHandler)
        .setIcon('ico-community')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.BOOKS_LIST:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        (displayContext.isAdmin || displayContext.isEditor);
      builder
        .setAutoTestId('menu-section books')
        .setHandler(this.booksListHandler)
        .setIcon('ico-library-outline')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.AFFILIATES:
      isVisible =
        !displayContext.isDevice &&
        displayContext.isCampaignLinksEnabled &&
        (displayContext.isSalesPerson || displayContext.isAdmin);
      builder
        .setAutoTestId('menu-section affiliates')
        .setHandler(this.affiliatesHandler)
        .setIcon('ico-exchange-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.AFFILIATE_DASHBOARD:
      isVisible =
        displayContext.isCampaignLinksEnabled &&
        displayContext.isAffiliate &&
        !displayContext.isSalesPerson;
      builder
        .setAutoTestId('menu-section affiliatesAdmin')
        .setHandler(this.affiliateDashboardHandler)
        .setIcon('ico-chart')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.TESTS:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR &&
        displayContext.brand === Brands.FFA &&
        (displayContext.isAdmin || displayContext.isEditor);
      builder
        .setAutoTestId('menu-section assessment')
        .setHandler(this.testsHandler)
        .setIcon('ico-task-line')
        .setIsVisible(isVisible);
      break;
    case AppMenuItemsEnum.EXTERNAL_BLOG:
      isVisible =
        !displayContext.isElectron &&
        displayContext.appMode === AppModeEnum.READER;
      builder
        .setAutoTestId('menu-section bloggerSettings')
        .setHandler(this.externalBlogHandler)
        .setIcon(displayContext.blogIcoClassName)
        .setIsVisible(isVisible)
        .setDisabled(!displayContext.isOnline)
        .setDescription(displayContext.blogDescription);
      break;
    case AppMenuItemsEnum.GOOGLE_ANALYTICS:
      isVisible =
        displayContext.appMode === AppModeEnum.EDITOR && displayContext.isAdmin;
      builder
        .setAutoTestId('menu-section googleAnalytics')
        .setHandler(this.googleAnalyticsHandler)
        .setIcon('ico-chart')
        .setIsVisible(isVisible);
      break;
    default:
      break;
  }
  return builder.build();
}

function createMenuSections(menuSectionsSchema, displayContext) {
  return menuSectionsSchema.map(menuItemType => {
    return this.createMenuItem(menuItemType, displayContext);
  });
}

export default {
  init,
  createMenuSections,

  createMenuItem,
  homeHandler() {
    const router = getRouter();
    router.push({ name: AppStateEnum.MANAGE_ABOUT });
  },
  compilationHandler() {
    const router = getRouter();
    router.push({ name: AppStateEnum.MANAGE_COMPILATION });
  },
  pricingHandler() {
    const router = getRouter();
    router.push({ name: AppStateEnum.PRICING });
  },
  activityDashboardHandler() {
    const router = getRouter();
    router.push({ name: AppStateEnum.ACTIVITY_DASHBOARD });
  },
  overviewHandler: () => {},
  assessmentHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.ASSESSMENT });
  },
  readModSettingsHandler: () => {
    const store = getStore();
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.READING_SETTINGS_POPUP,
      popupContext: {}
    });
  },
  libraryHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
  },
  librarySetHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.LIBRARY_SET });
  },
  resumeReadingHandler: () => {
    const store = getStore();
    OpenPublicationService.resumeReading(store);
  },
  exploreHandler: () => {
    const store = getStore();
    const router = getRouter();
    if (
      !store.getters['ContextStore/isSearchLoadingStopped'] &&
      !store.getters['ContextStore/isSearchLoaded']
    ) {
      store.dispatch('ContextStore/stopSearchLoading', true);
    }
    GoogleAnalyticsUtils.trackEvent('mainMenu', 'click', 'openSearch');
    router.push({ name: AppStateEnum.SEARCH });
  },
  dictionaryHandler: () => {},
  flashcardsHandler: () => {},
  newStudyProjectHandler: () => {
    const store = getStore();
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.CREATE_STUDY_COURSE,
      popupContext: {}
    });
  },
  async createNewCourseSyllabusHandler() {
    const syllabusId = await this.$_createSyllabusNew();
    OpenPublicationService.openSyllabus(syllabusId);
  },
  async createNewLibrarySetHandler() {
    try {
      const setId = await this.$_createLibrarySet();
      OpenPublicationService.openLibrarySetById(setId);
    } catch (error) {
      const store = getStore();
      store.dispatch('ManagePopupStore/openToaster', {
        text: 'You can create only one library set'
      });
    }
  },
  libraryContentEditorHandler: () => {
    const store = getStore();
    const editorUrl = store.getters['ContextStore/getClientEditorUrl'];
    window.open(editorUrl);
  },
  openShortenerPopup() {
    const store = getStore();
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.SHORTENER_POPUP,
      popupContext: {}
    });
  },
  blogHandler() {
    const store = getStore();
    const blogLink = store.getters['ContextStore/getClientBlogUrl'];
    store.dispatch('ContextStore/openUrl', {
      url: blogLink
    });
  },
  manageContentHandler: () => {},
  async logoutHandler() {
    const store = getStore();
    await store.dispatch('UserStore/logout');
    GoogleAnalyticsUtils.trackEvent('mainMenu', 'click', 'logOut');
  },
  loginHandler: () => {
    const store = getStore();
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.CONNECT,
      popupContext: {}
    });
  },
  aboutHandler: () => {},
  contactUsHandler: () => {
    const store = getStore();
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.CONTACT_US_POPUP,
      popupContext: {}
    });
  },
  applicationSettingsHandler: () => {
    const router = getRouter();
    GoogleAnalyticsUtils.trackEvent('mainMenu', 'click', 'openPreferances');
    router.push({ name: AppStateEnum.ACCOUNT_SETTINGS });
  },
  shareAppLinkHandler: () => {
    const store = getStore();
    const readerUrl = store.getters['ContextStore/getClientReaderUrl'];
    const userId = store.getters['UserStore/getUserId'];
    const shouldTrackUid = store.getters['ContextStore/brand'] === Brands.FFA;
    let linkToShare = `${readerUrl}about?`;
    if (shouldTrackUid) {
      linkToShare += `uid=${userId}`;
    }
    GoogleAnalyticsUtils.trackEvent('mainMenu', 'click', 'share');
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.SOCIAL_SHARING_POPUP,
      popupContext: {
        linkToShare: linkToShare
      }
    });
  },
  libraryViewHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
  },
  adminPanelHandler: () => {},
  reportsHandler: () => {},
  $_createSyllabusNew: async () => {
    // const store = getStore();
    // const syllabusId = await store.dispatch('SyllabusStore/createSyllabus');
    // return syllabusId;
    return '';
  },
  async $_createLibrarySet() {
    const store = getStore();
    const setId = await store.dispatch('LibraryStore/createSet');
    return setId;
  },
  manageUsersHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.MANAGE_USERS });
  },
  googleAnalyticsHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.GOOGLE_ANALYTICS });
  },
  booksListHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.BOOKS_LIST });
  },
  affiliatesHandler: () => {
    const store = getStore();
    const isDevice = store.getters['ContextStore/isDevice'];
    const isDeviceBrowser = store.getters['ContextStore/isDeviceBrowser'];
    const isElectron = store.getters['ContextStore/isElectron'];
    const isEditorApp = store.getters['ContextStore/isEditorApp'];
    if (isDevice || isDeviceBrowser || isElectron) {
      store.dispatch('ManagePopupStore/openToaster', {
        text: 'Affiliates functionality available only for desktop'
      });
    } else if (isEditorApp) {
      const router = getRouter();
      router.push({ name: AppStateEnum.AFFILIATES });
    } else {
      const editorUrl = store.getters['ContextStore/getClientEditorUrl'];
      window.open(`${editorUrl}affiliates/`);
    }
  },
  affiliateDashboardHandler: () => {
    const store = getStore();
    const userId = store.getters['UserStore/getUserId'];
    const isDevice = store.getters['ContextStore/isDevice'];
    const isDeviceBrowser = store.getters['ContextStore/isDeviceBrowser'];
    const isElectron = store.getters['ContextStore/isElectron'];
    const isEditorApp = store.getters['ContextStore/isEditorApp'];
    if (isDevice || isDeviceBrowser || isElectron) {
      store.dispatch('ManagePopupStore/openToaster', {
        text: 'Affiliates functionality available only for desktop'
      });
    } else if (isEditorApp) {
      const router = getRouter();
      router.push({
        name: AppStateEnum.AFFILIATES,
        params: { affiliateId: userId }
      });
    } else {
      const editorUrl = store.getters['ContextStore/getClientEditorUrl'];
      window.open(`${editorUrl}affiliates/${userId}`, '_blank');
    }
  },
  myCampaignsHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.MY_CAMPAIGNS });
  },
  testsHandler: () => {
    const router = getRouter();
    router.push({ name: AppStateEnum.TESTS });
  },
  openFavorites() {
    const router = getRouter();
    router.push({ name: AppStateEnum.FAVORITES });
  },
  openDashboard() {},
  openActivityReport() {},
  async externalBlogHandler() {
    const store = getStore();
    GoogleAnalyticsUtils.trackEvent(
      'mainMenu',
      'click',
      'externalBlogSettings'
    );
    const isOffline = !store.getters['ContextStore/isOnline'];
    if (isOffline) {
      const text = i18n.localize(
        'ExternalBlog.popup.noInternetConnection.label'
      );
      store.dispatch('ManagePopupStore/openErrorToaster', text);
      return;
    }
    await store.dispatch('ExternalBlogStore/handleAppMenuClick');
    store.dispatch('ManagePopupStore/closeAppMenu', {
      name: PopupNamesEnum.APP_MENU_WRAPPER
    });
  }
};
