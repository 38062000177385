import isObject from 'lodash/isObject';
import LibraryView from '@/classes/factories/LibraryView';
import PublicationInfoView from '@/classes/factories/PublicationInfoView';
import PublicationDetailsFactory from '@/classes/factories/PublicationDetailsFactory';
import LibraryStoreService from '@/services/LibraryStoreService';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';
import {
  CollectionItemView,
  RelatedStudyGuides
} from '@/classes/factories/library/PublicationsConstructors.js';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import PublicationStatusEnum from '@/enums/PublicationStatusEnum';
import FilterDurationRanges from '@/enums/FilterDurationRanges';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LibraryStore.js');

import VisibilityStatusesEnum from '@/enums/PublicationVisibilityStatusesEnum';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import AppModeEnum from '@/enums/AppModeEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import StoreKeys from '@/enums/StoreKeyEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';
import PublicationVisibilityStatusesEnum from '@/enums/PublicationVisibilityStatusesEnum';
import urlUtils from '@shared/utils/url';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import i18n from '@/i18n';
import CompilationsService from '@/services/CompilationsService';

import PublicationStatus from '@shared/classes/PublicationStatus';

const managePublicationsInitStates = Object.keys(
  ManagePublicationsStates
).reduce((statesMap, stateKey) => {
  const stateVal = ManagePublicationsStates[stateKey];
  statesMap[stateVal] = false;
  return statesMap;
}, {});

const initState = () => ({
  librarySearcher: null,
  categorySearcher: null,
  genreSearcher: null,
  collectionSearcher: null,
  authorSearcher: null,
  clintSideInited: false,
  library: {
    books: new LibraryView(),
    suggestedBooks: new LibraryView(),
    compilations: new LibraryView(),
    studyCourses: new LibraryView(),
    studyGuides: new LibraryView(),
    syllabuses: new LibraryView(),
    libraryset: new LibraryView(),
    relatedStudyGuides: {},
    publicationInfo: new PublicationInfoView()
  },
  intervalId: null,
  managePublicationsInitStates: managePublicationsInitStates,
  compilationCoversList: [],
  newBookIds: []
});

function createReadDetailsView(publication) {
  switch (publication.type) {
    case PublicationsTypes.BOOK:
      return PublicationDetailsFactory.createReadBookDetailsView(publication);
    case PublicationsTypes.COMPILATION:
      return PublicationDetailsFactory.createReadCompilationDetailsView(
        publication
      );
    case PublicationsTypes.COLLECTION:
      return PublicationDetailsFactory.createReadCollectionDetailsView(
        publication
      );
    default:
      logger.error(
        `Unsupported type ${publication.type} for create ReadDetailsView`
      );
      break;
  }
}

function createEditBookDetailsView(publication, { rootGetters, state }) {
  const userId = rootGetters['UserStore/getUserId'];
  switch (publication.type) {
    case PublicationsTypes.BOOK: {
      const publicationInfo = state.library.publicationInfo.getById(
        publication.id
      );
      return PublicationDetailsFactory.createEditBookDetailsView(publication, {
        relatedStudyGuides: publicationInfo.relatedStudyGuides
      });
    }
    case PublicationsTypes.COLLECTION:
      return PublicationDetailsFactory.createReadCollectionDetailsView(
        publication
      );
    case PublicationsTypes.STUDY_GUIDE: {
      const publicationInfo = state.library.publicationInfo.getById(
        publication.bookId
      );
      return PublicationDetailsFactory.createStudyGuideDetailsView(
        publication,
        publicationInfo.book,
        userId
      );
    }
    // case PublicationsTypes.SYLLABUS: {
    //   const syllabus = rootGetters['SyllabusStore/getSyllabusView'](
    //     publication.id
    //   );
    //   return PublicationDetailsFactory.createSyllabusDetailsView(
    //     publication,
    //     syllabus,
    //     userId
    //   );
    // }
    case PublicationsTypes.LIBRARY_SET: {
      const librarySetView =
        rootGetters['LibrarySetViewStore/getLibrarySetView'];
      return PublicationDetailsFactory.createLibrarySetViewDetailsView(
        publication,
        librarySetView
      );
    }
    default:
      logger.error(
        `Unsupported type ${publication.type} for create EditBookDetailsView`
      );
      break;
  }
}

function createDefaultEditBookDetailsView(publicationType, userId) {
  switch (publicationType) {
    case PublicationsTypes.SYLLABUS: {
      const publication = {};
      const syllabus = {};
      return PublicationDetailsFactory.createSyllabusDetailsView(
        publication,
        syllabus,
        userId
      );
    }
    case PublicationsTypes.LIBRARY_SET: {
      const publication = {};
      const librarySetView = {};
      return PublicationDetailsFactory.createLibrarySetViewDetailsView(
        publication,
        librarySetView
      );
    }
    default:
      logger.error(
        `Unsupported type ${publicationType} for create default EditBookDetailsView`
      );
      break;
  }
}

function serializeLibraryView(libraryView) {
  return {
    id: libraryView.id,
    name: libraryView.name,
    description: libraryView.description,
    price: libraryView.price,
    readingTime: libraryView.readingTime,
    autoExtendable: libraryView.autoExtendable,
    publicationIds: libraryView.publications.map(publication => {
      return publication.id;
    })
  };
}

function _getErrorHandler(dispatch) {
  return function _getErrorHandlerByType(type) {
    switch (type) {
      case AssetResourcesEnum.FS:
        return function loadPublicationErrorHandler(error, messageKey) {
          logger.error(
            `get error on load publication data form fs error: ${error}`
          );
          const text = i18n.localize(messageKey);
          dispatch(
            'ManagePopupStore/openErrorToaster',
            { text },
            { root: true }
          );
        };
      case AssetResourcesEnum.REMOTE:
        return function loadPublicationErrorHandler(error, messageKey) {
          logger.error(
            `get error on load publication  data form remote error: ${error}`
          );
          const text = i18n.localize(messageKey);
          dispatch(
            'ManagePopupStore/openErrorToaster',
            {
              text,
              clickLabel: 'Reload',
              clickHandler: () => {
                window.location.reload();
              }
            },
            { root: true }
          );
        };
      default:
        return function() {};
    }
  };
}

function getUniqStoreKeys(libraryTypes) {
  const storeKeys = libraryTypes.reduce((result, type) => {
    result.add(StoreKeys[type]);
    return result;
  }, new Set());
  return [...storeKeys];
}

const storeGetters = {
  toCategoryLink: () => (publication, originalCategory = false) => {
    const categoryName = urlUtils.getCategoryName(
      publication,
      originalCategory
    );
    return {
      name: AppStateEnum.CATEGORY,
      params: { name: categoryName }
    };
  },
  toGenreLink: () => (publication, genre) => {
    const genreName = urlUtils.getGenreName(publication, genre);
    return {
      name: AppStateEnum.GENRE_PAGE,
      params: { name: genreName }
    };
  },
  getLibrarySummary(state) {
    const summary = { totalPublications: 0 };
    for (const pubType of Object.keys(StoreKeys)) {
      const storeKey = StoreKeys[pubType];
      const libraryView = state.library[storeKey];
      if (libraryView && libraryView.publicationsList) {
        const number = Object.keys(libraryView.publicationsList).reduce(
          (num, lang) => {
            num += libraryView.publicationsList[lang].length;
            return num;
          },
          0
        );
        summary.totalPublications += number;
        summary[pubType] = number;
      }
    }
    return summary;
  },
  getLibraryState: state => state.library,
  getPublicationDetailsView: (
    state,
    getters,
    rootState,
    rootGetters
  ) => requestData => {
    const { publicationId, mode } = requestData;
    const publication = getters.getPublicationById(publicationId);
    if (!publication) {
      throw {
        name: CustomErrorEnum.EMPTY_DATA,
        message: `Can't find publication by id ${publication} for publication details.`
      };
    }
    switch (mode) {
      case AppModeEnum.READER:
        return createReadDetailsView(publication);
      case AppModeEnum.EDITOR:
        return createEditBookDetailsView(publication, {
          getters,
          rootGetters,
          state
        });
      default:
        break;
    }
  },

  getPublicationBySlugWithFallbackById: (state, getters) => slug => {
    let publication = getters.getPublicationBySlug(slug);
    if (!publication) {
      publication = getters.getPublicationById(slug);
    }
    return publication;
  },

  getDefaultPublicationDetailsView: (
    state,
    getters,
    rootState,
    rootGetters
  ) => requestData => {
    const { publicationType, mode } = requestData;
    const userId = rootGetters['UserStore/getUserId'];
    switch (mode) {
      case AppModeEnum.EDITOR:
        return createDefaultEditBookDetailsView(publicationType, userId);
      default:
        break;
    }
  },
  getNewBookIds: state => {
    return state.newBookIds;
  },
  getPublicationIdBySlug: (state, getters) => slug => {
    const publication = getters.getPublicationBySlug(slug);
    return publication?.id;
  },
  getPublicationSlugById: (state, getters) => publicationId => {
    const publication = getters.getPublicationById(publicationId);
    return publication?.slug;
  },
  // eslint-disable-next-line
  getCollectionItemViews: (state, getters) => ({ collectionId }) => {
    const collection = getters.getPublicationById(collectionId);
    if (!collection) {
      return [];
    }
    return collection.items.map(item => new CollectionItemView(item));
  },
  getPublicationById: state => publicationId =>
    _getPublicationById(publicationId, state),
  getPublicationBySlug: state => slug => _getPublicationBySlug(slug, state),
  searchPublications: (state, getters, rootState, rootGetters) => searcher => {
    const user = rootGetters['UserStore/getUser'];
    const libraryTypes = [...getPublicationTypesByState(searcher.state)];
    const storeKeys = getUniqStoreKeys(libraryTypes);
    let publicationList = [];
    if (rootGetters['ContextStore/isReaderApp'] || !user.adminRole) {
      const librarySetIndex = libraryTypes.findIndex(
        l => l === PublicationsTypes.LIBRARY_SET
      );
      if (librarySetIndex !== -1) {
        libraryTypes.splice(librarySetIndex, 1);
      }
    }
    for (const storeKey of storeKeys) {
      const libraryView = state.library[storeKey];
      const pubs = libraryView.search(searcher, user);

      [].push.apply(publicationList, pubs);
    }

    return publicationList;
  },
  getCategories: state => searcher => {
    const libraryTypes = getPublicationTypesByState(searcher.state);

    const categMap = {};
    const storeKeys = getUniqStoreKeys(libraryTypes);
    for (const storeKey of storeKeys) {
      const libraryView = state.library[storeKey];
      const pubs = libraryView.search(searcher);

      for (const pub of pubs) {
        categMap[pub.category] = null;
      }
    }

    return Object.keys(categMap);
  },
  getDefaultPublicationsByLanguage: (state, { searchPublications }) => lang => {
    const builder = SearchPublicationsFactory.getSearcherBuilder();
    const searcher = builder
      .setState(ManagePublicationsStates.LIBRARY)
      .setLanguage(lang)
      .setPublicationsStateFilter(VisibilityStatusesEnum.DEFAULT)
      .build();
    return searchPublications(searcher);
  },
  isManagePublicationsStateInit: state => managePublicationsState =>
    state.managePublicationsInitStates[managePublicationsState],
  getLibrarySet: (state, getters) => {
    const librarySetList = getters.getAllLibrarySet || [];
    return librarySetList.find(libraryItem => {
      return (
        libraryItem.type === PublicationsTypes.LIBRARY_SET &&
        libraryItem.isOriginal &&
        libraryItem.publicationStatus === PublicationStatusEnum.PUBLISHED
      );
    });
  },
  getAllLibrarySet(state) {
    return state?.library?.libraryset?.publicationsList?.common;
  },
  isPublicationInSet: (state, getters) => publicationId => {
    const librarySet = getters.getLibrarySet;
    if (!librarySet) {
      return false;
    }
    return librarySet.publicationIds.some(id => id === publicationId);
  },
  getSuggestedBooks(state, getters, rootState, rootGetters) {
    try {
      const currentLanguage = rootGetters['ContextStore/currentLanguageGetter'];
      const storeKey = StoreKeys[PublicationsTypes.SUGGESTED_BOOK];
      const suggestedBooksByLang = state.library[storeKey].publicationsList;
      const suggestedBooks = suggestedBooksByLang[currentLanguage] ?? [];
      return suggestedBooks;
    } catch (error) {
      logger.info(`No suggested books found ${error}`);
      return [];
    }
  },
  isSuggestedBlockVisible: (state, getters, rootState, rootGetters) => {
    const isSuggestionsEnabled =
      rootGetters['ContextStore/isSuggestionsEnabled'];
    const isGuestUser = rootGetters['UserStore/isGuestUser'];
    const hoursPassedInSuggestionSet =
      rootGetters['ProgressStore/getHoursPassedInSuggestionSet'];
    const userSuggestedBooks = rootGetters['LibraryStore/getSuggestedBooks'];
    return (
      !isGuestUser &&
      isSuggestionsEnabled &&
      hoursPassedInSuggestionSet < 20 &&
      userSuggestedBooks.length > 0
    );
  },
  getInitializedStates: state => {
    return Object.keys(state.managePublicationsInitStates).reduce(
      (states, stateKey) => {
        const initialized = state.managePublicationsInitStates[stateKey];
        if (initialized) {
          states.push(stateKey);
        }
        return states;
      },
      []
    );
  },
  searchLibraryBooks: (state, getters, rootState, rootGetters) => (
    searcher,
    includeCollectionBooksForNew = true
  ) => {
    const isSuggestedBlockVisible = getters.isSuggestedBlockVisible;
    if (!isSuggestedBlockVisible) {
      searcher = SearchPublicationsFactory.searcherToBuilder(searcher)
        .setExcludedCategories([CustomCategoriesEnum.SUGGESTED_BOOK])
        .build();
    }

    const recentBookIds = rootGetters['RecentBookStore/getRecentBookIds'];
    const newBookIds = getters['getNewBookIds'];
    const books = getters.searchPublications(searcher);
    const recentSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setState(ManagePublicationsStates.RECENT_BOOKS)
      .setAllowBookList(recentBookIds)
      .build();
    const recent = getters.searchPublications(recentSearcher);

    const newBooksSearcher = SearchPublicationsFactory.searcherToBuilder(
      searcher
    )
      .setState(ManagePublicationsStates.NEW_BOOKS)
      .setAllowBookList(newBookIds)
      .setCollectionIncluded(includeCollectionBooksForNew)
      .build();
    const newBooks = getters.searchPublications(newBooksSearcher);

    return [...books, ...recent, ...newBooks];
  },
  getLibrary(state, getters, rootState, rootGetters) {
    const currentLanguage = rootGetters['ContextStore/currentLanguageGetter'];
    const builder = SearchPublicationsFactory.getSearcherBuilder();
    builder
      .setState(ManagePublicationsStates.LIBRARY)
      .setLanguage(currentLanguage)
      .setFilter('');
    const searcher = builder.build();
    const books = getters.searchLibraryBooks(searcher);
    return books;
  },
  getLibrarySearcher(state) {
    return state.librarySearcher;
  },
  getCategorySearcher(state) {
    return state.categorySearcher;
  },
  getGenreSearcher(state) {
    return state.genreSearcher;
  },
  getCollectionSearcher(state) {
    return state.collectionSearcher;
  },
  getAuthorSearcher(state) {
    return state.authorSearcher;
  },
  getFilteredLibrary(state, getters) {
    if (!getters.getLibrarySearcher) {
      return [];
    }
    const books = getters.searchLibraryBooks(getters.getLibrarySearcher, false);
    return books;
  },
  getFilteredLibraryWithCollection: (state, getters) => searcher => {
    if (!searcher) {
      return [];
    }
    const newSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setCollectionIncluded(true)
      .build();
    return getters.searchLibraryBooks(newSearcher);
  },
  getFilteredLibraryWithoutDifficulty: (state, getters) => searcher => {
    if (!searcher) {
      return [];
    }
    const newSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setCollectionIncluded(true)
      .setDifficultyRange(null)
      .build();
    return getters.searchLibraryBooks(newSearcher);
  },
  getFilteredLibraryWithoutCategories: (state, getters) => searcher => {
    if (!searcher) {
      return [];
    }
    const newSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setCollectionIncluded(true)
      .setCategories(null)
      .build();
    return getters.searchLibraryBooks(newSearcher);
  },
  getFilteredLibraryWithoutDurations: (state, getters) => searcher => {
    if (!searcher) {
      return [];
    }
    const newSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setCollectionIncluded(true)
      .setDurations(null)
      .build();
    return getters.searchLibraryBooks(newSearcher);
  },
  getFilteredLibraryWithoutGenres: (state, getters) => searcher => {
    if (!searcher) {
      return [];
    }
    const newSearcher = SearchPublicationsFactory.searcherToBuilder(searcher)
      .setCollectionIncluded(true)
      .setGenres(null)
      .build();
    return getters.searchLibraryBooks(newSearcher);
  },
  getFilterProps: (state, getters) => (searcher, currentRouteName = '') => {
    // TODO optimize this getter
    const getDurationName = time => {
      const [name] = FilterDurationRanges.find(
        ([, min, max]) => time >= min && time < max
      );
      return name;
    };
    const groupFilterProps = library => {
      const exceptions = [
        CustomCategoriesEnum.SUGGESTED_BOOK,
        CustomCategoriesEnum.RECENT,
        CustomCategoriesEnum.NEW,
        PublicationsTypes.COLLECTION
      ];
      const exceptionToRemove = exceptions.indexOf(currentRouteName);
      if (exceptionToRemove !== -1) {
        exceptions.splice(exceptionToRemove, 1);
      }
      const props = {
        languages: [],
        audio: 0,
        offline: 0,
        difficulties: {},
        categories: {},
        durations: {},
        genres: {}
      };
      for (const pub of library) {
        const durationName = getDurationName(pub.readingTime);
        const difficultyKey = parseInt(pub.difficulty);
        const category = props.categories[pub.category];
        const difficulty = props.difficulties[difficultyKey];
        const durations = props.durations[durationName];
        const count = pub.type === PublicationsTypes.COLLECTION ? 0 : 1;
        props.categories[pub.category] = (category ?? 0) + count;
        if (exceptions.includes(pub.category)) {
          continue;
        }
        props.difficulties[difficultyKey] = (difficulty ?? 0) + count;
        props.audio += pub.audio ? count : 0;
        props.offline +=
          pub.isContentDownloaded && pub.isAudioDownloaded ? count : 0;
        props.durations[durationName] = (durations ?? 0) + count;
        if (!pub.genres?.length) {
          continue;
        }
        for (const genre of pub.genres) {
          props.genres[genre] = (props.genres[genre] ?? 0) + count;
        }
      }
      return props;
    };
    const mergeFilterProps = (original, filtered) => {
      const merged = {};
      for (const [key, val] of Object.entries(original)) {
        const fVal = filtered[key];
        if (isObject(val)) {
          merged[key] = mergeFilterProps(val, fVal);
          continue;
        }
        merged[key] = fVal ?? 0;
      }
      return merged;
    };
    const original = groupFilterProps(getters.getLibrary);
    const filtered = groupFilterProps(
      getters.getFilteredLibraryWithCollection(searcher)
    );
    const filteredWithoutDifficulty = mergeFilterProps(
      original,
      groupFilterProps(getters.getFilteredLibraryWithoutDifficulty(searcher))
    );
    const filteredWithoutCategories = mergeFilterProps(
      original,
      groupFilterProps(getters.getFilteredLibraryWithoutCategories(searcher))
    );
    const filteredWithoutDurations = mergeFilterProps(
      original,
      groupFilterProps(getters.getFilteredLibraryWithoutDurations(searcher))
    );
    const filteredWithoutGenres = mergeFilterProps(
      original,
      groupFilterProps(getters.getFilteredLibraryWithoutGenres(searcher))
    );
    const merged = mergeFilterProps(original, filtered);
    return {
      filterProps: merged,
      filterPropsWithoutGenres: filteredWithoutGenres,
      filterPropsWithoutDifficulty: filteredWithoutDifficulty,
      filterPropsWithoutCategories: filteredWithoutCategories,
      filterPropsWithoutDurations: filteredWithoutDurations
    };
  },
  getCompilationCoversList(state) {
    return state.compilationCoversList;
  }
};

const actions = {
  createReport(_, { bookIds }) {
    return LibraryStoreService.createReport(bookIds);
  },

  async createSet({ commit }) {
    const response = await LibraryStoreService.createLibrarySet();
    const rawLibrarySet = response.data;
    commit('addLibrarySet', {
      rawLibrarySet
    });

    return rawLibrarySet.id;
  },

  async createCopy({ commit }, { originalSetId }) {
    const response = await LibraryStoreService.createCopyLibrarySet(
      originalSetId
    );
    const rawLibrarySet = response.data;
    const copyId = rawLibrarySet.id;
    commit('addLibrarySet', {
      rawLibrarySet
    });
    commit('updatePublication', {
      pubId: originalSetId,
      attrs: {
        copyId
      }
    });

    return copyId;
  },
  async persistLibrarySet({ commit }, payload) {
    const { librarySetView } = payload;
    const query = serializeLibraryView(librarySetView);
    const response = await LibraryStoreService.persistLibrarySet(query);
    const rawLibrarySet = response.data;
    commit('updatePublication', {
      pubId: librarySetView.id,
      attrs: query
    });

    return rawLibrarySet.id;
  },
  async startActualizeStores({ commit, dispatch }) {
    await dispatch('actualizeStores');
    const updateInterval = AppConstantsUtil.UPDATE_LIBRARY_INTERVAL;
    let intervalId = setTimeout(function actualize() {
      dispatch('actualizeStores');
      intervalId = setTimeout(actualize, updateInterval);
    }, updateInterval);
    commit('setIntervalId', intervalId);
  },
  stopActualizeStores({ commit, state }) {
    clearTimeout(state.intervalId);
    commit('setIntervalId', null);
  },
  async getNewBookIds({ commit }) {
    const newBookIds = await LibraryStoreService.getNewBookIds();
    commit('setNewBookIds', newBookIds);
  },
  actualizeStores({ dispatch, getters, rootGetters }) {
    const isOnline = rootGetters['ContextStore/isOnline'];
    if (!isOnline) {
      return;
    }
    const managePublicationsStates = getters.getInitializedStates;
    const promises = managePublicationsStates.map(managePublicationsState => {
      return dispatch('actualizeLibraryState', {
        managePublicationsState,
        forceRemote: true
      });
    });
    return Promise.all(promises);
  },
  async actualizeLibraryState(
    { dispatch },
    { managePublicationsState, forceRemote }
  ) {
    const publicationsTypes = getPublicationTypesByState(
      managePublicationsState
    );
    await dispatch('initStateByPublicationTypes', {
      publicationsTypes,
      forceRemote
    });
  },
  async deletePublication({ dispatch }, { id }) {
    await AssetsManager.removePublication(id);
    dispatch('updatePublicationSource', {
      bookId: id
    });
  },
  async removePublicationsFromSource(
    { commit, dispatch, getters },
    { source, pubTypes, excludes }
  ) {
    await AssetsManager.removeAll(
      source,
      excludes[PublicationsTypes.BOOK] || []
    );
    const removePromises = pubTypes.map(pubType => {
      const tableName = StoreKeys[pubType];
      const exclude = excludes && excludes[pubType] ? excludes[pubType] : [];
      if (exclude.length) {
        return LibraryStoreService.clearTable(tableName, source, exclude);
      } else {
        return LibraryStoreService.removeTable(tableName, source);
      }
    });
    await Promise.all(removePromises);

    const managePublicationsStates = getters.getInitializedStates;
    commit('destroyManagePublicationsStates', managePublicationsStates);
    const statePromises = managePublicationsStates.map(
      managePublicationsState => {
        dispatch('initState', {
          managePublicationsState,
          forceRemote: false
        });
      }
    );
    await Promise.all(statePromises);
  },
  async removePublication({ commit, dispatch, state }, payload) {
    const { publicationId, managePublicationsState } = payload;
    const publication = _getPublicationById(publicationId, state);
    if (publication.assetsSource === AssetResourcesEnum.FS) {
      await dispatch('removePublicationById', publicationId);
    }
    commit('removePublication', { publicationId, type: publication.type });
    await dispatch('reinitState', {
      managePublicationsState,
      forceRemote: false
    });
  },
  async reinitState(
    { commit, dispatch },
    { managePublicationsState, forceRemote }
  ) {
    commit('destroyManagePublicationsStates', [managePublicationsState]);
    await dispatch('initState', {
      managePublicationsState,
      forceRemote
    });
  },
  async updatePublicationSource({ commit, dispatch, getters }, payload) {
    const { bookId } = payload;
    const publication = getters.getPublicationById(bookId);
    const contentSource = await AssetsManager.getAssetSource(
      bookId,
      AssetTypeEnum.CONTENT
    );
    let isAudioDownloaded = false;
    const offlineSources = [AssetResourcesEnum.FS];
    if (publication.audio) {
      const audioSource = await AssetsManager.getAssetSource(
        bookId,
        AssetTypeEnum.AUDIO
      );
      isAudioDownloaded = offlineSources.includes(audioSource);
    }

    const updateData = {
      pubId: bookId,
      attrs: {
        assetsSource: contentSource,
        isContentDownloaded: offlineSources.includes(contentSource),
        isAudioDownloaded
      }
    };
    commit('updatePublication', updateData);

    if (contentSource === AssetResourcesEnum.FS) {
      await dispatch('savePublicationInDbStore', publication);
    } else {
      await dispatch('removePublicationById', publication.id);
    }
  },
  actualizeSyllabus({ commit }, payload) {
    //TODO: remove this after refactor store
    commit('actualizeSyllabus', payload);
  },

  async actualizeBook({ commit, dispatch, state }, payload) {
    const { publicationId } = payload;
    commit('actualizeBook', payload);
    const publication = _getPublicationById(publicationId, state);
    await dispatch('savePublicationInDbStore', publication);
  },
  // eslint-disable-next-line no-empty-pattern
  async createStudyGuide({ commit }, { publicationId }) {
    const rawStudyGuide = await LibraryStoreService.createStudyGuide(
      publicationId
    );
    commit('addStudyGuide', {
      rawStudyGuide
    });
    return {
      studyGuideId: rawStudyGuide._id
    };
  },
  async persistMaterialStatus({ commit, state }, payload) {
    const { publicationId, status } = payload;
    const publication = _getPublicationById(publicationId, state);
    await LibraryStoreService.persistMaterialStatus(
      publicationId,
      status,
      publication.type
    );
    commit('updatedMaterialStatus', payload);
    return Promise.resolve();
  },
  async persistDefaultStudyGuide({ commit }, payload) {
    const { studyGuideId, publicationId } = payload;
    await LibraryStoreService.persistDefaultStudyGuide(
      publicationId,
      studyGuideId
    );
    commit('setDefaultStudyGuide', {
      studyGuideId,
      publicationId
    });
  },
  async duplicateCompilation() {
    //{ commit }, publicationId
    logger.error('Need implement duplicateCompilation');
    return Promise.resolve();
  },
  async deleteCompilation() {
    //{ commit }, publicationId
    logger.error('Need implement deleteCompilation');
    return Promise.resolve();
  },
  async persistPublicationAccessStatus(
    { commit, dispatch, getters },
    { publication, status }
  ) {
    const publicationId = publication.id;
    const publicationAccessStatus = status;
    const language = publication.language;
    const statuses = [];
    const newPubStatus = new PublicationStatus();
    statuses.push(
      newPubStatus
        .setId(publicationId)
        .setStatus(publicationAccessStatus)
        .setLanguage(language)
        .build()
    );
    if (publication.type === PublicationsTypes.COLLECTION) {
      const originalPublication = getters.getPublicationById(publication.id);
      const collectionItems = originalPublication.items.filter(
        p =>
          p.accessStatus !== PublicationVisibilityStatusesEnum.NOT_IN_COLLECTION
      );
      const collectionItemsStatuses = collectionItems.map(pub => {
        const newCollectionItemStatus = new PublicationStatus();
        return newCollectionItemStatus
          .setId(pub.id)
          .setStatus(publicationAccessStatus)
          .setLanguage(pub.language)
          .build();
      });
      statuses.push(...collectionItemsStatuses);
    }
    const collection = publication.collection
      ? getters.getPublicationById(publication.collection)
      : null;
    if (
      collection &&
      collection.items.find(cPub => cPub.id === publication.id)
    ) {
      let needToUpdateCollectionStatus =
        publicationAccessStatus === PublicationVisibilityStatusesEnum.AVAILABLE;
      if (
        publicationAccessStatus !==
        PublicationVisibilityStatusesEnum.NOT_AVAILABLE
      ) {
        const availablePubInCollection = collection.items.filter(
          colPub =>
            colPub.accessStatus ===
              PublicationVisibilityStatusesEnum.AVAILABLE &&
            colPub.id !== publication.id
        );
        needToUpdateCollectionStatus = !availablePubInCollection.length;
      }
      if (needToUpdateCollectionStatus) {
        const newCollectionItemStatus = new PublicationStatus();
        newCollectionItemStatus
          .setId(collection.id)
          .setStatus(publicationAccessStatus)
          .setLanguage(collection.language);
        statuses.push(newCollectionItemStatus.build());
      }
    }
    const response = await LibraryStoreService.persistPublicationAccessStatus(
      statuses
    );
    if (response.data?.message) {
      dispatch(
        'ManagePopupStore/openErrorToaster',
        { text: 'Server error, the publication status was not changed' },
        { root: true }
      );
      logger.error(
        `Got error from server on publication "${publicationId}" status change ${response.message}`
      );
      return;
    }
    statuses.forEach(newStatus =>
      commit('updatePublicationAccessStatus', {
        publicationId: newStatus.id,
        publicationAccessStatus: newStatus.status,
        language: newStatus.language
      })
    );
  },
  async getRemoteBookInfo({ commit }, publicationId) {
    const rawBookInfo = await LibraryStoreService.getRemoteBookInfo(
      publicationId
    );
    commit('setIlmId', {
      publicationId,
      ilmId: rawBookInfo.book.ilmId
    });
    commit('setPublishInfo', {
      publicationId,
      publishInfo: rawBookInfo.book.publish
    });
    commit('setPublicationInfo', {
      publicationId,
      publicationInfo: rawBookInfo
    });
    commit('setDefaultStudyGuide', {
      publicationId,
      studyGuideId: rawBookInfo.defaultStudyGuideId || '' //TODO: need fix on server side
    });
    commit('setCurrentStudyGuide', {
      publicationId,
      studyGuideId: rawBookInfo.currentStudyGuideId
    });
  },

  // eslint-disable-next-line no-unused-vars
  async getPublicationByIdFromSource({ commit }, requestData) {
    const { publicationId, source } = requestData;
    switch (source) {
      case AssetResourcesEnum.REMOTE: {
        const pubInfo = await LibraryStoreService.getRemoteBookInfo(
          publicationId
        );
        commit('setPublicationInfo', {
          publicationInfo: pubInfo,
          publicationId: publicationId
        });
        break;
      }
      default:
        logger.error(`Unsuported source for get publication by id`);
        break;
    }
  },

  async erasePublication({ commit, state }, { publicationId }) {
    const publication = _getPublicationById(publicationId, state);
    const type = publication.type;
    await LibraryStoreService.erasePublication(publicationId, type);
    commit('removePublication', { publicationId, type });
  },

  async createStudyGuideCopy({ commit }, { studyGuideId }) {
    const response = await LibraryStoreService.createStudyGuideCopy(
      studyGuideId
    );
    const studyGuideCopy = response.data;
    const studyGuideCopyId = studyGuideCopy._id;
    commit('addStudyGuide', { rawStudyGuide: studyGuideCopy });
    commit('updateStudyGuideCopyId', { studyGuideId, studyGuideCopyId });
    return studyGuideCopyId;
  },

  async pushChanges({ commit, state }, { publicationId }) {
    const publication = _getPublicationById(publicationId, state);
    switch (publication.type) {
      case PublicationsTypes.LIBRARY_SET:
        {
          const { id: originalId, copyId } = publication;
          const response = await LibraryStoreService.pushChangesToOriginalLibrarySet(
            originalId,
            copyId
          );
          const rawLibraryView = response.data;
          commit('removePublication', {
            publicationId: copyId,
            type: publication.type
          });
          commit('actualizeLibrarySet', {
            originalId,
            rawLibraryView
          });
          commit('calcLibrarySetReadingTime');
        }
        break;
      case PublicationsTypes.STUDY_GUIDE: {
        const { id, studyGuideCopyId } = publication;
        const response = await LibraryStoreService.pushChangesToOriginalStudyGuide(
          id,
          studyGuideCopyId
        );
        const updatedMaterial = response.data;
        commit('updateStudyGuide', {
          studyGuideId: id,
          studyGuide: updatedMaterial
        });
        commit('removePublication', {
          publicationId: studyGuideCopyId,
          type: publication.type
        });
        break;
      }
      default:
        break;
    }
  },

  destroyStates({ commit }, managePublicationsStates) {
    commit('destroyManagePublicationsStates', managePublicationsStates);
  },

  async initState(
    { commit, getters, dispatch, state },
    { managePublicationsState, forceRemote }
  ) {
    if (!state.clintSideInited) {
      commit('setClintSideInited', false);
    }
    if (getters.isManagePublicationsStateInit(managePublicationsState)) {
      return null;
    }
    const publicationsTypes = getPublicationTypesByState(
      managePublicationsState
    );
    commit('setInitManagePublicationsStates', managePublicationsState);

    await dispatch('initStateByPublicationTypes', {
      publicationsTypes,
      forceRemote
    });
    return null;
  },
  transformToClientData({ commit, state }) {
    const libraryTypes = Object.keys(StoreKeys);
    const pubTypes = libraryTypes.reduce((result, val) => {
      result[StoreKeys[val]] = val;
      return result;
    }, {});
    const storeKeys = getUniqStoreKeys(libraryTypes);
    for (const storeKey of storeKeys) {
      const libraryViewData = state.library[storeKey];
      if (!libraryViewData) {
        continue;
      }
      commit('fillLibraryView', {
        storeKey,
        publicationsData: libraryViewData.publicationsList,
        publicationType: pubTypes[storeKey],
        source: AssetResourcesEnum.REMOTE
      });
    }
  },
  async initStateByPublicationTypes(
    { commit, dispatch, state, rootGetters },
    { publicationsTypes, forceRemote }
  ) {
    try {
      const isSuggestionsEnabled =
        rootGetters['ContextStore/isSuggestionsEnabled'];
      const getErrorHandlerByType = _getErrorHandler(dispatch);
      const isGuestUser = rootGetters['UserStore/isGuestUser'];
      const isLoggingOut = rootGetters['UserStore/isLoggingOut'];
      const libraryData = await LibraryStoreService.loadStoreData({
        publicationsTypes,
        errorHandler: getErrorHandlerByType,
        forceRemote,
        isSuggestionsEnabled,
        isGuestUser,
        isLoggingOut
      });
      const publicationsData = libraryData.extractPublicationsData();
      const relatedStudyGuides = libraryData.extractRelatedStudyGuides();
      const suggestedBooks = libraryData.extractSuggestedBooks();
      commit('setRelatedStudyGuides', { relatedStudyGuides });
      await dispatch('saveRelatedStudyGuidesInDbStore', {
        relatedStudyGuides
      });

      commit('destroyLibraryView', { publicationsTypes });
      const updatedPublications = [];
      for (const publicationType of publicationsTypes) {
        const storeKey = StoreKeys[publicationType];
        if (publicationType === PublicationsTypes.SUGGESTED_BOOK) {
          for (const lang in suggestedBooks) {
            suggestedBooks[lang] = suggestedBooks[lang].reduce((books, pub) => {
              const originalPub = _getPublicationById(pub.id, state);
              if (originalPub) {
                const suggested = Object.assign({}, originalPub, pub);
                books.push(suggested);
              }
              return books;
            }, []);
          }
          commit('fillLibraryView', {
            storeKey,
            publicationsData: suggestedBooks,
            publicationType,
            source: AssetResourcesEnum.REMOTE
          });
        } else {
          for (const libraryDataSet of publicationsData) {
            commit('fillLibraryView', {
              storeKey,
              publicationsData: libraryDataSet.publicationsData,
              publicationType,
              source: libraryDataSet.source
            });
            if (state.library[storeKey]?.updatedPublications?.length) {
              updatedPublications.push(
                ...state.library[storeKey].updatedPublications
              );
            }
          }
        }
      }
      if (publicationsTypes.includes(PublicationsTypes.LIBRARY_SET)) {
        commit('calcLibrarySetReadingTime');
      }
      if (updatedPublications.length) {
        await Promise.all(
          updatedPublications.map(updatedPublication =>
            dispatch('savePublicationInDbStore', updatedPublication)
          )
        );
      }
      return null;
    } catch (error) {
      logger.error(`initStateByPublicationTypes failed with ${error}`);
    }
  },
  async savePublicationInDbStore({ getters }, publication) {
    let updatedPublication = publication;
    const isCollectionItem = Boolean(publication.collection);
    if (isCollectionItem) {
      updatedPublication = getters.getPublicationById(publication.collection);
    }
    const tableName = StoreKeys[publication.type];
    await LibraryStoreService.savePublicationData(
      tableName,
      updatedPublication.toParsedJson()
    );
    return null;
  },

  async searchBooks({ commit }, { filters, isFiltered }) {
    try {
      const { libraryData, booksCount } = await LibraryStoreService.searchBooks(
        filters
      );
      commit('setFilteredBooks', { libraryData, isFiltered });
      return {
        booksCount,
        booksLength: libraryData?.[filters.filterLanguage]?.length
      };
    } catch (error) {
      logger.error(`Search books failed with ${error}`);
    }
  },

  async removePublicationById({ commit, dispatch, getters }, publicationId) {
    let pubIdForRemove = publicationId;
    const publication = getters.getPublicationById(publicationId);
    const isCollectionItem = Boolean(publication.collection);
    if (isCollectionItem) {
      commit('updatePublication', {
        pubId: publicationId,
        attrs: {
          assetsSource: AssetResourcesEnum.REMOTE,
          isContentDownloaded: false,
          isAudioDownloaded: false
        }
      });
      await dispatch('savePublicationInDbStore', publication);
      return;
    }
    const tableName = StoreKeys[publication.type];
    await LibraryStoreService.removePublicationById(tableName, pubIdForRemove);
  },

  async saveRelatedStudyGuidesInDbStore({ dispatch }, { relatedStudyGuides }) {
    const preparedStudyGuides = new RelatedStudyGuides(
      StoreKeys.RELATED_STUDYGUIDES,
      relatedStudyGuides
    );
    try {
      await dispatch('savePublicationInDbStore', preparedStudyGuides);
    } catch (err) {
      logger.error(`Error on saving relatedStudyGuides to IndexedDb: ${err}`);
    }
  },

  reset({ commit }, resetOptions) {
    commit('setDefaultState', resetOptions);
  },
  async applyLibraryFilter({ commit, dispatch, getters }, searcher) {
    if (
      !getters.isManagePublicationsStateInit(ManagePublicationsStates.LIBRARY)
    ) {
      await dispatch('initState', {
        managePublicationsState: ManagePublicationsStates.LIBRARY
      });
    }
    commit('setLibrarySearcher', searcher);
  },
  async initCompilationCoversList({ commit, rootGetters }) {
    const serverUrl = rootGetters['ContextStore/getServerUrl'];
    const compilationCoversList = await CompilationsService.getCompilationCoversList(
      serverUrl
    );
    commit('setCompilationCoversList', compilationCoversList);
  }
};

const mutations = {
  setClintSideInited(state, val) {
    state.clintSideInited = val;
  },
  calcLibrarySetReadingTime(state) {
    const setStoreKey = StoreKeys[PublicationsTypes.LIBRARY_SET];
    const bookStoreKey = StoreKeys[PublicationsTypes.BOOK];
    const librarySetView = state.library[setStoreKey];
    const bookView = state.library[bookStoreKey];

    const isLibrarySetEmpty =
      Object.keys(librarySetView.publicationsList).length === 0;
    if (!isLibrarySetEmpty) {
      for (const librarySet of librarySetView) {
        const publicationIds = librarySet.getPublicationIds();
        const readingTime = publicationIds.reduce((time, publicationId) => {
          const publication = bookView.getById(publicationId);
          if (publication) {
            time += publication.readingTime;
          }
          return time;
        }, 0);
        librarySet.setReadingTime(readingTime);
      }
    }
  },
  setNewBookIds(state, newBookIds) {
    state.newBookIds = newBookIds;
  },
  setFilteredBooks(state, { libraryData, isFiltered }) {
    let libraryView = new LibraryView();
    libraryView.fill(
      libraryData,
      PublicationsTypes.BOOK,
      AssetResourcesEnum.REMOTE,
      true
    );
    if (
      Object.keys(state.library.books?.publicationsList).length === 0 ||
      isFiltered
    ) {
      state.library.books = libraryView;
      return;
    }
    state.library.books.mergeWith(libraryView);
  },
  fillLibraryView(
    state,
    { storeKey, publicationsData, publicationType, source }
  ) {
    let libraryView =
      state.library[storeKey] instanceof LibraryView
        ? state.library[storeKey]
        : new LibraryView();
    libraryView.fill(publicationsData, publicationType, source);
    state.library[storeKey] = libraryView;
  },
  destroyLibraryView(state, { publicationsTypes }) {
    publicationsTypes.forEach(pubType => {
      const storeKey = StoreKeys[pubType];
      state.library[storeKey] = new LibraryView();
    });
  },
  destroyLibrary(state) {
    for (const [storeKey] of Object.entries(state.library)) {
      state.library[storeKey] = new LibraryView();
    }
  },
  actualizeSyllabus(state, { studyCourse }) {
    //TODO: remove this after refactor store
    const id = studyCourse._id;
    const syllabus = _getPublicationById(id, state);
    if (!syllabus) {
      return;
    }
    syllabus.name = studyCourse.name;
    syllabus.readingTime = studyCourse.readingTime;
    syllabus.difficulty = studyCourse.difficulty;
    syllabus.description = studyCourse.description;
    state.library.syllabuses.replacePublication(id, syllabus);
  },
  actualizeLibrarySet(state, { originalId, rawLibraryView }) {
    const storeKey = StoreKeys[PublicationsTypes.LIBRARY_SET];
    const libraryView = state.library[storeKey];
    libraryView.replacePublication(originalId, rawLibraryView);
  },
  actualizeBook(state, payload) {
    const { meta, publicationId } = payload;
    const book = _getPublicationById(publicationId, state);
    if (!book) {
      return;
    }
    book.updateByMeta(meta);
    state.library.books.replacePublication(publicationId, book);
  },
  addStudyGuide(state, payload) {
    const { rawStudyGuide } = payload;
    const storeKey = StoreKeys[PublicationsTypes.STUDY_GUIDE];
    const libraryView = state.library[storeKey];
    return libraryView.addPublication(rawStudyGuide);
  },
  addLibrarySet(state, payload) {
    const { rawLibrarySet } = payload;
    const storeKey = StoreKeys[PublicationsTypes.LIBRARY_SET];
    const libraryView = state.library[storeKey];
    return libraryView.addPublication(rawLibrarySet);
  },
  addStudyClass(state, payload) {
    const { studyClass } = payload;
    const storeKey = StoreKeys[PublicationsTypes.STUDY_COURSE];
    const libraryView = state.library[storeKey];
    return libraryView.addPublication(studyClass);
  },
  setLibraryData(state, storeData) {
    state.library[storeData.storeKey] = storeData.libraryView;
  },
  setPublicationInfo(state, payload) {
    const { publicationInfo, publicationId } = payload;
    state.library.publicationInfo.update(publicationId, publicationInfo);
  },
  setIlmId(state, payload) {
    const { ilmId, publicationId } = payload;
    const publication = _getPublicationById(publicationId, state);
    publication.setIlmId(ilmId);
  },
  setPublishInfo(state, payload) {
    const { publishInfo, publicationId } = payload;
    const publication = _getPublicationById(publicationId, state);
    publication.setPublishInfo(publishInfo);
  },
  setBookInfo(state, payload) {
    // mocked solution
    const { publicationId, rawBookInfo } = payload;
    state.library.publicationInfo.update(
      publicationId,
      rawBookInfo.relatedStudyGuides
    );
  },
  setRelatedStudyGuides(state, { relatedStudyGuides }) {
    state.library.relatedStudyGuides = relatedStudyGuides;
  },

  removePublication(state, { publicationId, type }) {
    const storeKey = StoreKeys[type];
    state.library[storeKey].removePublication(publicationId);
  },
  updatedMaterialStatus(state, { publicationId, status }) {
    const publication = _getPublicationById(publicationId, state);
    publication.setStatus(status);
  },
  updateStudyGuide(state, { studyGuideId, studyGuide }) {
    state.library.studyGuides.replacePublication(studyGuideId, studyGuide);
  },
  updateStudyGuideCopyId(state, { studyGuideId, studyGuideCopyId }) {
    const studyGuide = state.library.studyGuides.getById(studyGuideId);
    studyGuide.setStudyGuideCopyId(studyGuideCopyId);
  },
  updatePublicationAccessStatus(state, payload) {
    const { publicationId, publicationAccessStatus } = payload;
    const publication = _getPublicationById(publicationId, state);
    publication.setAccessStatus(publicationAccessStatus);
  },
  updateCompilationIsDefault(state, { publicationId, isDefault }) {
    const publication = state.library.compilations.getById(publicationId);
    publication.setDefault(isDefault);
  },
  updateCompilationCover(state, { publicationId, coverFileName }) {
    const publication = state.library.compilations.getById(publicationId);
    publication.setCoverFileName(coverFileName);
  },
  setDefaultStudyGuide(state, payload) {
    const { publicationId, studyGuideId } = payload;
    const publication = _getPublicationById(publicationId, state);
    publication.defaultStudyGuideId = studyGuideId;
  },
  setCurrentStudyGuide(state, payload) {
    const { publicationId, studyGuideId } = payload;
    const publication = _getPublicationById(publicationId, state);
    publication.currentStudyGuideId = studyGuideId;
  },
  setInitManagePublicationsStates(state, managePublicationsState) {
    state.managePublicationsInitStates[managePublicationsState] = true;
  },
  destroyManagePublicationsStates(state, managePublicationsStates) {
    managePublicationsStates.forEach(managePublicationsState => {
      state.managePublicationsInitStates[managePublicationsState] = false;
    });
  },
  setIntervalId(state, intervalId) {
    state.intervalId = intervalId;
  },
  setDefaultState(state, payload) {
    const { excludeBookIds = [] } = payload || {};
    const excludeMap = excludeBookIds.reduce((eMap, bookId) => {
      const pub = _getPublicationById(bookId, state);

      const storeKey = StoreKeys[pub.type];
      if (!eMap.hasOwnProperty(storeKey)) {
        eMap[storeKey] = [];
      }
      const collectionId = pub.collection;
      const collection = _getPublicationById(collectionId, state);
      if (collection) {
        const collectionKey = StoreKeys[PublicationsTypes.COLLECTION];

        eMap[collectionKey] = eMap[collectionKey] || [];
        eMap[collectionKey].push(collectionId);
        eMap[collectionKey].push(bookId);
      } else {
        eMap[storeKey].push(bookId);
      }
      return eMap;
    }, {});
    const defaultState = initState();
    const PUBLICATIONS_KEY = 'library';
    Object.keys(state).forEach(key => {
      if (key !== PUBLICATIONS_KEY) {
        state[key] = defaultState[key];
        return;
      }
      Object.keys(state[key]).forEach(storeKey => {
        const libraryView = state[key][storeKey];
        if (excludeMap.hasOwnProperty(storeKey)) {
          libraryView.resetToDefault(excludeMap[storeKey]);
        } else {
          state[key][storeKey] = defaultState[key][storeKey];
        }
      });
    });
  },
  updatePublication(state, { pubId, attrs }) {
    const publication = _getPublicationById(pubId, state);
    const suggestedPublication = _getPublicationById(
      pubId,
      state,
      PublicationsTypes.SUGGESTED_BOOK
    );

    if (!publication) {
      logger.error(
        `Couldn't find publication in store with #${pubId} for update publication`
      );
      return;
    }

    for (const attr in attrs) {
      publication[attr] = attrs[attr];

      if (suggestedPublication) {
        suggestedPublication[attr] = attrs[attr];
      }
    }
  },
  setLibrarySearcher(state, searcher) {
    state.librarySearcher = searcher;
  },
  setCategorySearcher(state, searcher) {
    state.categorySearcher = searcher;
  },
  setGenreSearcher(state, searcher) {
    state.genreSearcher = searcher;
  },
  setCollectionSearcher(state, searcher) {
    state.collectionSearcher = searcher;
  },
  setAuthorSearcher(state, searcher) {
    state.authorSearcher = searcher;
  },
  setCompilationCoversList(state, compilationCoversList) {
    state.compilationCoversList = compilationCoversList;
  }
};

const statePublicationTypeMap = {
  [ManagePublicationsStates.LIBRARY]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET,
    PublicationsTypes.SUGGESTED_BOOK
  ],
  [ManagePublicationsStates.LIBRARY_SET]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.BOOK_LIST]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.RECENT_BOOKS]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.NEW_BOOKS]: [PublicationsTypes.BOOK],
  [ManagePublicationsStates.FAVORITES]: [PublicationsTypes.BOOK],
  [ManagePublicationsStates.LIBRARY_EDITOR]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.SYLLABUS,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.INSIDE_SYLLABUS]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.STUDY_GUIDE
  ],
  [ManagePublicationsStates.COLLECTION]: [PublicationsTypes.BOOK],
  [ManagePublicationsStates.INSIDE_LIBRARY_SET]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.STUDY]: [PublicationsTypes.STUDY_COURSE],
  [ManagePublicationsStates.COMPILATIONS]: [
    PublicationsTypes.COMPILATION,
    PublicationsTypes.BOOK
  ],
  [ManagePublicationsStates.MY_MATERIALS]: [
    PublicationsTypes.STUDY_GUIDE,
    PublicationsTypes.LIBRARY_SET,
    PublicationsTypes.SYLLABUS
  ],
  [ManagePublicationsStates.ABOUT]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ],
  [ManagePublicationsStates.PRICING]: [
    PublicationsTypes.BOOK,
    PublicationsTypes.LIBRARY_SET
  ]
};

function getValueFromMap(key, map) {
  if (!map.hasOwnProperty(key)) {
    throw new Error(`Passed wrong key for map: ${key}`);
  }
  return map[key];
}

function getPublicationTypesByState(state) {
  return getValueFromMap(state, statePublicationTypeMap);
}

function _getPublicationBySlug(slug, state) {
  const pubTypes = Object.keys(state.library);
  const iterator = pubTypes[Symbol.iterator]();
  for (let pubType of iterator) {
    const libraryView = state.library[pubType];
    let publication;
    try {
      publication = libraryView.getBySlug(slug);
    } catch (e) {
      publication = null;
    }
    if (publication) {
      return publication;
    }
  }
  return null;
}

function _getPublicationById(publicationId, state, publicationType) {
  const pubTypes = Object.keys(state.library);
  const iterator = publicationType ? [StoreKeys[publicationType]] : pubTypes;
  for (let pubType of iterator) {
    const libraryView = state.library[pubType];
    let publication;
    try {
      publication = libraryView.getById(publicationId);
    } catch (e) {
      publication = null;
    }
    if (publication) {
      return publication;
    }
  }
  return null;
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
