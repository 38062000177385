<template>
  <v-menu
    v-model="showMenu"
    offset-y
    :z-index="zIndex"
    class="language-switcher-menu"
  >
    <template #activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <div v-if="hide" class="language-switcher hidden" v-on="slotProps.on">
          <BaseSpriteIcon icon-name="ico-global-line" />
        </div>
        <div v-else class="language-switcher current px-6" v-on="slotProps.on">
          <BaseSpriteIcon icon-name="ico-global-line" />
          <span class="px-2">{{ selected.text }}</span>
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </div>
      </slot>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in languagesItems"
        :key="index"
        @click="changeLang(item)"
      >
        <v-list-item-title class="language-item-title">
          <div>
            {{ item.text }}
          </div>
          <BaseSpriteIcon
            :custom-class="getIconCustomClass(item)"
            icon-name="ico-check"
          />
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'LanguagesSwitcher',
  components: {
    BaseSpriteIcon
  },
  props: {
    languagesList: Array,
    currentLang: String,
    hide: Boolean,
    zIndex: {
      type: Number,
      default: 11
    }
  },
  data() {
    const languagesItems = this.languagesList.map(this.$_createLangItem);
    const selected = this.$_createLangItem(this.currentLang);
    return {
      languagesItems,
      showMenu: false,
      selected
    };
  },
  methods: {
    changeLang(lang) {
      this.selected = this.$_createLangItem(lang.value);
      this.$emit('onLanguageChanged', {
        language: lang.value
      });
      this.showMenu = false;
    },
    toggleVisibility() {
      this.showMenu = !this.showMenu;
    },
    getIconCustomClass(item) {
      return item.value === this.selected.value ? 'selected-language' : '';
    },
    $_createLangItem(lang) {
      return {
        text: this.$t(`Language.${lang}.label`),
        value: lang
      };
    }
  }
};
</script>

<style lang="less" src="./LanguagesSwitcher.less"></style>
